import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { requestNonce, verifySignature, isAuthenticated, isAdmin } from '../services/adminService';
import bs58 from 'bs58';

// Check if Phantom wallet is installed and connected
const getProvider = () => {
  if ('phantom' in window) {
    const provider = (window as any).phantom?.solana;
    if (provider?.isPhantom) {
      return provider;
    }
  }
  
  window.open('https://phantom.app/', '_blank');
  return null;
};

interface LocationState {
  from?: string;
}

const LoginPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const from = location.state?.from || '/';
  
  // Check if user is already authenticated
  useEffect(() => {
    if (isAuthenticated()) {
      if (from === '/admin' && !isAdmin()) {
        setError('You do not have admin privileges.');
      } else {
        history.push(from);
      }
    }
  }, [from, history]);
  
  // Handle wallet connection and authentication
  const connectWallet = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const provider = getProvider();
      if (!provider) {
        setError('Phantom wallet not found. Please install Phantom wallet.');
        setLoading(false);
        return;
      }
      
      // Connect to wallet
      const resp = await provider.connect();
      const address = resp.publicKey.toString();
      setWalletAddress(address);
      
      // Request a nonce from the server
      const nonce = await requestNonce(address);
      
      // Create message to sign
      const message = `Sign this message to authenticate with RPS: ${nonce}`;
      const encodedMessage = new TextEncoder().encode(message);
      
      // Request signature from wallet
      const signedMessage = await provider.signMessage(encodedMessage, 'utf8');
      const signature = signedMessage.signature; // This is a Uint8Array
      
      // Convert signature to Base58 string for sending to server
      const signatureBase58 = bs58.encode(signature);
      
      // Verify signature with the server and get JWT token
      const authResult = await verifySignature(address, signatureBase58);
      
      // Check if user has admin privileges if trying to access admin page
      if (from === '/admin' && !authResult.user.isAdmin) {
        setError('You do not have admin privileges.');
        setLoading(false);
        return;
      }
      
      // Successfully authenticated, redirect
      history.push(from);
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err.message || 'Failed to authenticate with wallet');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="flex items-center justify-center p-4 mt-10">
      <div className="w-full max-w-md p-6 rounded-xl shadow-lg bg-[#1b1830] text-white">
        <h1 className="text-3xl font-bold mb-6 text-center">Admin Login</h1>
        
        {error && (
          <div className="bg-red-800 text-white p-3 rounded-lg mb-6 text-center">
            {error}
          </div>
        )}
        
        <p className="mb-6 text-center">
          Connect your Solana wallet to access the admin dashboard.
        </p>
        
        <div className="flex justify-center">
          <button
            onClick={connectWallet}
            disabled={loading}
            className="px-6 py-3 bg-[#9509B7] hover:bg-[#7A0498] rounded-md font-semibold transition-colors flex items-center space-x-2 disabled:opacity-50"
          >
            {loading ? (
              <>
                <div className="w-5 h-5 border-t-2 border-pink border-opacity-50 rounded-full animate-spin"></div>
                <span>Connecting...</span>
              </>
            ) : (
              <>
                <span>Login via Phantom Wallet</span>
              </>
            )}
          </button>
        </div>
        
        {walletAddress && (
          <div className="mt-6 text-center text-gray-400">
            <p>Connected wallet:</p>
            <p className="font-mono text-sm truncate">
              {walletAddress}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage; 