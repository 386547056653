import { useState, useEffect } from 'react';
import { getAvatarUrl } from '../services/fileService';

export function useAvatarUrl(avatarPath: string | undefined | null) {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!avatarPath) {
      setAvatarUrl(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    getAvatarUrl(avatarPath)
      .then(url => {
        setAvatarUrl(url);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Error getting avatar URL:', err);
        setError(err);
        setIsLoading(false);
      });
  }, [avatarPath]);

  return { avatarUrl, isLoading, error };
} 