import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import React, { lazy, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { openModal } from "../helpers";


const WalletNotConnected: React.FC = () => {
    const wallet = useWallet();
    const { setVisible } = useWalletModal();

    const handleConnectWallet = () => {
        // @ts-ignore - Phantom may not be in window types
        if (isMobile && !window.phantom?.solana?.isPhantom) {
            // Use Phantom embedded for mobile devices
            const baseURL = encodeURIComponent(window.location.origin);
            const phantomURL = `https://phantom.app/ul/browse/${baseURL}?ref=${Date.now()}`;

            window.location.href = phantomURL;
        } else {
            // Use wallet adapter for desktop
            setVisible(true);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-full mt-12 px-4">
            <div className="text-center mb-8">
                <h1 className="text-white font-['Audiowide'] text-[30px] leading-normal">
                    Double Your SOL by Playing Rock, Paper, Scissors!
                </h1>
            </div>

            <img
                src="/img/rps_logo_big.png"
                alt="Rock Paper Scissors Logo"
                className="w-64 h-64 mb-8"
            />

            <button
                onClick={handleConnectWallet}
                className="w-full max-w-md py-2 bg-pink text-white font-semibold rounded-md mb-8"
            >
                Connect wallet
            </button>


            <div className="flex gap-6 mt-6">
                <button
                    onClick={openModal("faq-modal")}
                    className="text-white font-['Audiowide'] text-[14px] underline"
                >
                    FAQ
                </button>
                <button
                    onClick={openModal("howtoplay-modal")}
                    className="text-white font-['Audiowide'] text-[14px] underline"
                >
                    HOW TO PLAY
                </button>
                <button
                    onClick={openModal("referral-modal")}
                    className="text-white font-['Audiowide'] text-[14px] underline"
                >
                    Referral Program
                </button>
            </div>
        </div>
    );
};

export default WalletNotConnected; 