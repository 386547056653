import { useTranslation } from 'react-i18next';

export default function FAQModal() {
    const { t } = useTranslation();
    
    return (
        <dialog id="faq-modal" className="modal">
            <div className="modal-box rps-modal-box">
                <h2 className="text-2xl font-bold mb-4">{t('faq.title')}</h2>

                <h3 className="text-lg font-semibold mb-13">
                    <strong>{t('faq.whatIs')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.whatIsAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.whoToPlay')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.whoToPlayAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.howToStart')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.howToStartAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.howMuchToBet')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.howMuchToBetAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.isFair')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.isFairAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.depositWithdraw')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.depositWithdrawAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.areThereFees')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.areThereFeesAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.mobilePlay')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.mobilePlayAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.referralProgram')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.referralProgramAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.riskFreePlay')}</strong>
                </h3>
                <p className="mb-4">
                    {t('faq.riskFreePlayAnswer')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('faq.support')}</strong>
                </h3>
                <p className="mb-6">
                    {t('faq.supportAnswer')}
                </p>

                <div className="modal-action flex justify-end gap-2">
                    <form method="dialog" className="w-full">
                        <button className="w-full py-2 bg-pink text-white font-semibold rounded-md">
                            {t('common.gotIt')}
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}
