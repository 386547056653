import React, { useState, useEffect } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import * as anchor from "@coral-xyz/anchor";
import idl from "../../imports/idl/luckysol.json";
import { useTranslation } from 'react-i18next';
import ReferralLink from "../components/ReferralLink";
import { useUser } from "../context/UserContext";
import { 
  getReferralLeaderboard,
  ReferralData 
} from "../services/referralService";

// Define the types based on the IDL structure
interface ReferrerBalanceAccount {
    referrer: PublicKey;
    balance: anchor.BN;
}

export default function ReferralPage() {
    const { t } = useTranslation();
    const wallet = useWallet();
    const { connection } = useConnection();
    const { user } = useUser();
    const [referrerBalance, setReferrerBalance] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [claimStatus, setClaimStatus] = useState<string | null>(null);
    const [referralData, setReferralData] = useState<ReferralData[]>([]);
    const [loading, setLoading] = useState(true);

    // Fetch referral leaderboard data
    useEffect(() => {
        const fetchReferralData = async () => {
            try {
                setLoading(true);
                const data = await getReferralLeaderboard();
                setReferralData(data);
            } catch (error) {
                console.error("Error fetching referral data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchReferralData();
    }, []);

    // Helper function to get the anchor program
    const getProgram = () => {
        if (!wallet.publicKey) return null;
        const provider = new anchor.AnchorProvider(
            connection,
            wallet as any,
            anchor.AnchorProvider.defaultOptions()
        );
        // @ts-ignore - Ignore IDL type issues
        return new anchor.Program(idl, provider);
    };

    // Get the program and check for any accumulated referrer rewards
    useEffect(() => {
        const fetchReferrerBalance = async () => {
            if (!wallet.publicKey) return;
            
            try {
                // Find the referrer PDA account for the user's public key
                const programID = new PublicKey(idl.address);
                const [referrerPDA] = await PublicKey.findProgramAddress(
                    [Buffer.from("referrer"), wallet.publicKey.toBuffer()],
                    programID
                );

                // Get the program
                const program = getProgram();
                if (!program) {
                    console.error("Could not initialize program");
                    return;
                }

                try {
                    // Try to fetch the referrer account data
                    // The account name must match the one in the IDL
                    // @ts-ignore - Anchor typing issues
                    const referrerAccount = await program.account.referrerBalance.fetch(referrerPDA);
                    if (referrerAccount && referrerAccount.balance) {
                        // Convert balance from lamports to SOL
                        const balanceInSol = referrerAccount.balance.toNumber() / 1000000000;
                        setReferrerBalance(balanceInSol);
                    }
                } catch (err) {
                    // Account probably doesn't exist yet, which is fine
                    console.log(t('referral.errors.noAccount'), err);
                    setReferrerBalance(0);
                }
            } catch (err) {
                console.error(t('referral.errors.balanceFetch'), err);
                setReferrerBalance(0);
            }
        };

        fetchReferrerBalance();
    }, [wallet.publicKey, connection, t]);

    const handleClaimRewards = async () => {
        if (!wallet.publicKey || !referrerBalance || referrerBalance <= 0) {
            setClaimStatus(t('referral.errors.noRewards'));
            return;
        }

        setIsLoading(true);
        setClaimStatus(t('referral.status.processing'));

        try {
            // Get the program
            const program = getProgram();
            if (!program) {
                throw new Error("Could not initialize program");
            }

            // Find the program ID
            const programID = new PublicKey(idl.address);

            // Derive the PDA for the referrer account
            const [referrerPDA] = await PublicKey.findProgramAddress(
                [Buffer.from("referrer"), wallet.publicKey.toBuffer()],
                programID
            );

            // Call the claim_referrer_reward instruction
            const transaction = await program.methods
                .claimReferrerReward()
                .accounts({
                    referrer: wallet.publicKey,
                    referrerData: referrerPDA,
                    systemProgram: anchor.web3.SystemProgram.programId,
                })
                .rpc();

            // Wait for confirmation
            await connection.confirmTransaction(transaction, "confirmed");
            
            // Update the balance
            setReferrerBalance(0);
            setClaimStatus(t('referral.status.claimSuccess', { transaction }));
        } catch (error: unknown) {
            console.error(t('referral.errors.claimError'), error);
            const errorMessage = error instanceof Error ? error.message : String(error);
            setClaimStatus(t('referral.errors.claimFailed', { error: errorMessage }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto max-w-4xl">
            <h1 className="text-2xl font-bold text-center my-8">{t('referral.title')}</h1>
            <div className="w-full h-full px-4 py-14">
                {wallet?.publicKey && (
                    <ReferralLink solanaAddress={wallet.publicKey.toString()} />
                )}

                {/* Referrer Rewards Section */}
                {wallet?.publicKey && (
                    <div className="bg-purple p-4 rounded-lg mb-6 mt-8">
                        <h2 className="text-xl font-bold mb-4">{t('referral.rewards.title')}</h2>
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div>
                                <p className="text-sm mb-2">{t('referral.rewards.accumulated')}:</p>
                                <p className="text-2xl font-bold">
                                    {referrerBalance !== null ? 
                                        t('referral.rewards.balance', { amount: referrerBalance.toFixed(2) }) : 
                                        t('common.loading')}
                                </p>
                            </div>
                            <button
                                onClick={handleClaimRewards}
                                disabled={isLoading || !referrerBalance || referrerBalance <= 0}
                                className={`bg-gradient-to-r from-primary to-secondary text-white px-6 py-2 rounded-lg 
                                    ${(isLoading || !referrerBalance || referrerBalance <= 0) ? 
                                    'opacity-50 cursor-not-allowed' : 'hover:from-secondary hover:to-primary'}`}
                            >
                                {isLoading ? t('referral.status.processing') : t('referral.actions.claim')}
                            </button>
                        </div>
                        {claimStatus && (
                            <div className={`mt-4 p-3 rounded ${claimStatus.includes('Error') ? 
                                'bg-red-100 text-red-800' : 
                                'bg-green-100 text-green-800'}`}>
                                {claimStatus}
                            </div>
                        )}
                    </div>
                )}

                <div className="bg-purple p-4 rounded-lg hidden">
                    <h2 className="text-xl font-bold mb-4">{t('referral.leaderboard.title')}</h2>
                    {loading ? (
                        <p className="text-center">{t('common.loading')}</p>
                    ) : (
                        <table className="min-w-full table-auto">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 text-left">{t('referral.leaderboard.columns.rank')}</th>
                                    <th className="px-4 py-2 text-left">{t('referral.leaderboard.columns.degen')}</th>
                                    <th className="px-4 py-2 text-right">{t('referral.leaderboard.columns.earned')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {referralData.map((row: ReferralData, index: number) => (
                                <tr
                                    key={row.referrerId}
                                    className={`${index % 2 === 1 ? "bg-even-row" : ""}`}
                                >
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">{row.degen}</td>
                                    <td className="px-4 py-2 text-right">{t('referral.leaderboard.solAmount', { amount: row.commission.toFixed(4) })}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
