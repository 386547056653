import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfettiProps {
  isVisible?: boolean;
}

const Confetti: React.FC<ConfettiProps> = ({ isVisible = true }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  if (!show) return null;

  return (
    <img 
      src="/gif/confetti.gif" 
      alt={t('gameFlow.confettiAlt')}
      className="absolute w-full h-full object-cover top-[-50px] left-0"
      style={{ 
        pointerEvents: 'none',
        WebkitAnimationIterationCount: '1',
        animationIterationCount: '1'
      }}
    />
  );
};

export default Confetti; 