/**
 * Game Settings Service
 * Mock implementation for game settings that would typically be stored in a database
 */

import { get, put, post } from './apiService';

/**
 * Interface for game settings
 */
export interface GameSettings {
  minBet: number;
  maxBet: number;
  houseEdge: number;
  referralCommission: number;
  maintenanceMode: boolean;
}

/**
 * Interface for bet game settings
 */
export interface BetGameSettings {
  min_bet: number;
  max_bet: number;
  max_volume_hourly: number;
  max_volume_daily: number;
}

/**
 * Default game settings
 */
const defaultSettings: GameSettings = {
  minBet: 0.01,
  maxBet: 10,
  houseEdge: 3,
  referralCommission: 1,
  maintenanceMode: false
};

/**
 * Default bet settings
 */
const defaultBetSettings: BetGameSettings = {
  min_bet: 0.01,
  max_bet: 5,
  max_volume_hourly: 100,
  max_volume_daily: 1000
};

/**
 * Get the current game settings
 * @returns Promise resolving to the current game settings
 */
export const getGameSettings = async (): Promise<GameSettings> => {
  try {
    const response = await get('/api/settings/game');
    return response.data as GameSettings;
  } catch (error) {
    console.error('Error getting game settings:', error);
    // Return default settings while API is being developed
    return { ...defaultSettings };
  }
};

/**
 * Update game settings (Admin only)
 * @param settings The updated game settings
 * @returns Promise resolving to the updated game settings
 */
export const updateGameSettings = async (settings: Partial<GameSettings>): Promise<GameSettings> => {
  try {
    const response = await post('/api/settings/game', settings);
    return response.data as GameSettings;
  } catch (error) {
    console.error('Error updating game settings:', error);
    // Return mock success for now while API is being developed
    return { 
      ...defaultSettings,
      ...settings
    };
  }
};

/**
 * Toggle maintenance mode (Admin only)
 * @param enabled Whether maintenance mode should be enabled
 * @returns Promise resolving to the updated game settings
 */
export const toggleMaintenanceMode = async (enabled: boolean): Promise<GameSettings> => {
  try {
    const response = await post('/api/settings/maintenance', { enabled });
    return response.data as GameSettings;
  } catch (error) {
    console.error('Error toggling maintenance mode:', error);
    // Return mock success for now while API is being developed
    return {
      ...defaultSettings,
      maintenanceMode: enabled
    };
  }
};

/**
 * Get a specific site setting value
 * @param settingName The name of the setting to retrieve
 * @returns Promise resolving to the setting value
 */
export const getSiteSetting = async (settingName: string): Promise<number> => {
  try {
    const response = await get<{ value: number }>(`/api/site/settings/${settingName}`);
    // Return the value or a default value if not set
    return response.data.value || getDefaultValue(settingName);
  } catch (error) {
    console.error(`Error getting setting ${settingName}:`, error);
    return getDefaultValue(settingName);
  }
};

/**
 * Get all game settings for betting at once
 * @returns All bet game settings
 */
export const getAllGameSettings = async (): Promise<BetGameSettings> => {
  try {
    const response = await get<{ settings: Record<string, number> }>('/api/site/settings');
    const settings = response.data.settings;
    
    // Combine fetched settings with defaults for any missing settings
    return {
      min_bet: settings.min_bet ?? defaultBetSettings.min_bet,
      max_bet: settings.max_bet ?? defaultBetSettings.max_bet,
      max_volume_hourly: settings.max_volume_hourly ?? defaultBetSettings.max_volume_hourly,
      max_volume_daily: settings.max_volume_daily ?? defaultBetSettings.max_volume_daily
    };
  } catch (error) {
    console.error('Error fetching all game settings:', error);
    return { ...defaultBetSettings };
  }
};

/**
 * Update a game setting
 * @param settingName The name of the setting to update
 * @param value The new value for the setting
 * @returns Promise resolving to a success indicator
 */
export const updateGameSetting = async (settingName: string, value: number): Promise<boolean> => {
  try {
    const response = await put<{ success: boolean }>(`/api/site/settings/${settingName}`, { value });
    return response.data.success;
  } catch (error) {
    console.error(`Error updating setting ${settingName}:`, error);
    return false;
  }
};

/**
 * Check if a user is enabled (not banned)
 * @param walletAddress The user's wallet address
 * @returns boolean indicating if the user is enabled
 */
export const isUserEnabled = async (walletAddress: string): Promise<boolean> => {
  // In a real implementation, this would check against a database
  // For now, we'll return true for all users except a test address
  const disabledWallets = ['testDisabledWallet123'];
  const isEnabled = !disabledWallets.includes(walletAddress);
  
  return new Promise(resolve => {
    setTimeout(() => resolve(isEnabled), 100);
  });
};

/**
 * Get the commission rate from the blockchain program
 * @returns Promise resolving to the current commission rate
 */
export const getCommissionRate = async (): Promise<number> => {
  try {
    const response = await get<{ rate: number }>('/site/commission-rate');
    return response.data.rate;
  } catch (error) {
    console.error('Error getting commission rate:', error);
    return 1; // Default to 1%
  }
};

/**
 * Update the commission rate on the blockchain program
 * @param rate The new commission rate value
 * @returns Promise resolving to a success indicator
 */
export const updateCommissionRate = async (rate: number): Promise<boolean> => {
  try {
    const response = await put<{ success: boolean }>('/site/commission-rate', { rate });
    return response.data.success;
  } catch (error) {
    console.error('Error updating commission rate:', error);
    return false;
  }
};

/**
 * Get the default value for a setting if it's not found in the database
 * @param settingName The name of the setting
 * @returns The default value for the setting
 */
function getDefaultValue(settingName: string): number {
  const defaults: Record<string, number> = {
    min_bet: 0.01,
    max_bet: 5,
    max_volume_hourly: 100,
    max_volume_daily: 1000,
    houseEdge: 3,
    referralCommission: 1,
  };
  
  return defaults[settingName] || 0;
} 