import * as anchor from "@coral-xyz/anchor";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import classnames from "classnames";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import idl from "../../imports/idl/luckysol.json";
import WalletNotConnected from "./WalletNotConnected";
import GameFlow from "./GameFlow";
import { getSiteSetting, isUserEnabled, getAllGameSettings } from "../services/gameSettingsService";
import { createGame, getUserVolume, finalizeGame, getVolumeLimits } from "../services/gameService";
import { useGameContext } from "../context/GameContext";
import { useStatsContext } from "../context/StatsContext";
import { useUser } from "../context/UserContext";

// Define the interface for game settings to improve type safety
interface GameSettings {
    minBet: number;
    maxBet: number;
    hourlyVolumeLimit: number;
    dailyVolumeLimit: number;
}

type PlayMode = "SOL" | "TOKEN";
type GameState = "betting" | "countdown" | "result";

interface GameResult {
    houseChoice: number;
    result: 'Win' | 'Lose' | 'Tie';
    payout: number;
    house_choice?: number;
}

interface Game {
    _id: string;
    gameIdNumber: number;
    username: string;
    gameResult: GameResult;
    status: string;
    createdAt: Date;
}

// Type for the response from finalizeGame API
interface GameResponse {
    _id: string;
    gameIdNumber: number;
    username: string;
    gameResult: {
        house_choice: number;
        result: string;
        payout: number;
    };
    status: string;
    createdAt: Date;
}

const getRandomInt = () => Math.floor(Math.random() * 10001);

const BetForm: React.FC = () => {
    const { t } = useTranslation();
    const { refreshUser } = useUser();
    const [betAmount, setBetAmount] = useState<number>(0);
    const [userChoice, setUserChoice] = useState<number | null>(null);
    const [houseChoice, setHouseChoice] = useState<number | null>(null);
    const [playMode, setPlayMode] = useState<PlayMode>("SOL");
    const [loading, setLoading] = useState<boolean>(false);
    const [settingsLoading, setSettingsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [settingsError, setSettingsError] = useState<string | null>(null);
    const [gameState, setGameState] = useState<GameState>("betting");
    const [currentGame, setCurrentGame] = useState<Game | null>(null);
    const [showGameFlow, setShowGameFlow] = useState<boolean>(false);
    const [shouldStartProgress, setShouldStartProgress] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<number | undefined>();
    const [isUserDisabled, setIsUserDisabled] = useState<boolean>(false);
    const [gameSettings, setGameSettings] = useState<GameSettings>({
        minBet: 0.01,
        maxBet: 5,
        hourlyVolumeLimit: 100,
        dailyVolumeLimit: 1000
    });
    const [userHourlyVolume, setUserHourlyVolume] = useState<number>(0);
    const [userDailyVolume, setUserDailyVolume] = useState<number>(0);
    const [toast, setToast] = useState<{message: string, type: 'success' | 'error' | 'info'} | null>(null);

    // Get context functions
    const { refreshGames } = useGameContext();
    const { refreshStats } = useStatsContext();

    const { connection } = useConnection();
    const wallet = useWallet();
    const programID = new PublicKey(idl.address);
    const [treasuryPDA, setTreasuryPDA] = useState<PublicKey | null>(null);

    // Load treasury PDA
    useEffect(() => {
        const findTreasuryPDA = async () => {
            const [pda] = await PublicKey.findProgramAddress(
                [Buffer.from("treasury")],
                programID
            );
            setTreasuryPDA(pda);
        };
        findTreasuryPDA();
    }, []);

    // Function to load game settings
    const loadGameSettings = useCallback(async () => {
        setSettingsLoading(true);
        setSettingsError(null);
        
        try {
            // Use single API call to get all settings at once instead of multiple calls
            const settings = await getAllGameSettings();
            
            setGameSettings({
                minBet: settings.min_bet,
                maxBet: settings.max_bet,
                hourlyVolumeLimit: settings.max_volume_hourly,
                dailyVolumeLimit: settings.max_volume_daily
            });
        } catch (error) {
            console.error("Error loading game settings:", error);
            setSettingsError("Failed to load game settings");
        } finally {
            setSettingsLoading(false);
        }
    }, []);
    
    // Function to load user-specific data
    const loadUserData = useCallback(async () => {
        if (!wallet.publicKey) return;
        
        try {
            // Check if user is enabled
            const enabled = await isUserEnabled(wallet.publicKey.toString());
            setIsUserDisabled(!enabled);

            // Load user's recent volume
            try {
                const volumeData = await getUserVolume(wallet.publicKey.toString());
                setUserHourlyVolume(volumeData.hourly);
                setUserDailyVolume(volumeData.daily);
            } catch (err) {
                console.error('Error fetching user volume:', err);
                showToast('Unable to retrieve your current volume limits', 'error');
            }
        } catch (error) {
            console.error("Error loading user data:", error);
        }
    }, [wallet.publicKey]);

    // Load all required data when component mounts or wallet changes
    useEffect(() => {
        // Load settings even if wallet isn't connected
        loadGameSettings();
        
        // Only load user data if wallet is connected
        if (wallet.publicKey) {
            loadUserData();
        }
    }, [wallet.publicKey, loadGameSettings, loadUserData]);

    const getProgram = () => {
        if (!wallet.publicKey) return null;
        const provider = new anchor.AnchorProvider(
            connection,
            wallet as any,
            anchor.AnchorProvider.defaultOptions()
        );
        // @ts-ignore - Ignoring IDL type error as this is a known issue with Anchor IDL typing
        return new anchor.Program(idl, provider);
    };

    function validateSetBetAmount(value: number) {
        setShowGameFlow(false);
        if (isNaN(value)) {
            setBetAmount(gameSettings.minBet);
        } else if (value > gameSettings.maxBet) {
            setBetAmount(gameSettings.maxBet);
            showToast(`Maximum bet is ${gameSettings.maxBet} SOL`, 'info');
        } else if (value < gameSettings.minBet) {
            setBetAmount(gameSettings.minBet);
            showToast(`Minimum bet is ${gameSettings.minBet} SOL`, 'info');
        } else {
            setBetAmount(value);
        }
    }

    const handlePlayModeChange = (mode: PlayMode) => {
        setShowGameFlow(false);
        setPlayMode(mode);
    };

    const handleMoveChoice = (choice: number) => {
        setShowGameFlow(false);
        setUserChoice(choice);
    };

    const resetGame = () => {
        setShowGameFlow(false);
        setGameState("betting");
        setUserChoice(null);
        setHouseChoice(null);
        setCurrentGame(null);
        setBetAmount(0);
        setError(null);
    };

    const handleContinue = () => {
        setGameState("betting");
        setShowGameFlow(false);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (isUserDisabled) {
            setError(t('betting.errors.accountDisabled'));
            return;
        }

        if (!betAmount || betAmount <= 0) {
            setError(t('betting.errors.invalidAmount'));
            return;
        }
        
        if (betAmount < gameSettings.minBet) {
            setError(t('betting.errors.belowMinBet', { min: gameSettings.minBet }));
            return;
        }
        if (betAmount > gameSettings.maxBet) {
            setError(t('betting.errors.aboveMaxBet', { max: gameSettings.maxBet }));
            return;
        }
        
        // Check volume limits
        if (userHourlyVolume + betAmount > gameSettings.hourlyVolumeLimit) {
            setError(t('betting.errors.hourlyVolumeExceeded', { limit: gameSettings.hourlyVolumeLimit }));
            return;
        }
        
        if (userDailyVolume + betAmount > gameSettings.dailyVolumeLimit) {
            setError(t('betting.errors.dailyVolumeExceeded', { limit: gameSettings.dailyVolumeLimit }));
            return;
        }
        
        if (userChoice === null) {
            setError(t('betting.errors.noChoice'));
            return;
        }
        if (!wallet.connected || !treasuryPDA) {
            setError(t('betting.errors.walletNotConnected'));
            return;
        }

        const program = getProgram();
        if (!program) {
            setError(t('betting.errors.programNotInitialized'));
            return;
        }

        try {
            setLoading(true);
            setError(null);
            setShowGameFlow(true);
            setGameState("countdown");
            setShouldStartProgress(false); // Reset progress state
            setStartTime(undefined); // Reset start time

            // Create a new game using our service
            const { gameId, gameIdNumber } = await createGame(wallet.publicKey?.toString() || '');
            console.log("gameIdNumber", gameIdNumber);

            const gameIdBN = new anchor.BN(gameIdNumber);
            const depositLamports = new anchor.BN(
                betAmount * anchor.web3.LAMPORTS_PER_SOL
            );

            const [pendingBetPDA] = await PublicKey.findProgramAddress(
                [
                    Buffer.from("pending_bet"),
                    wallet.publicKey!.toBuffer(),
                    gameIdBN.toArrayLike(Buffer, "le", 8),
                ],
                program.programId
            );

            // @ts-ignore - Ignoring the treasury property type error temporarily
            const treasuryAccount = await program.account.treasury.fetch(treasuryPDA);
            const commissionWalletPubkey = new PublicKey(
                treasuryAccount.commissionWallet
            );

            let userAta: PublicKey | null = null;
            let treasuryAta: PublicKey | null = null;
            const isTokenDeposit = playMode === "TOKEN";
            if (isTokenDeposit) {
                const mint = new PublicKey(treasuryAccount.luckysolMint);
                userAta = await getAssociatedTokenAddress(mint, wallet.publicKey!, false);
                treasuryAta = new PublicKey("BsRW3ettS4bBEU3oc2Gk5b5A3okXyjsYh9BdQgowz7GZ");
            }

            const accountsObj: any = {
                user: wallet.publicKey,
                treasury: treasuryPDA,
                commissionWallet: commissionWalletPubkey,
                pendingBet: pendingBetPDA,
                tokenProgram: anchor.utils.token.TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            };
            if (isTokenDeposit) {
                accountsObj["treasuryAta"] = treasuryAta;
                accountsObj["userAta"] = userAta;
            } else {
                accountsObj["treasuryAta"] = null;
                accountsObj["userAta"] = null;
            }

            console.log("accountsObj", JSON.stringify(accountsObj), "userChoice", userChoice?.toString(), "isTokenDeposit", isTokenDeposit.toString(), "depositLamports", depositLamports.toString());

            const txSignature = await program.methods
                .deposit(
                    gameIdBN,
                    new anchor.BN(userChoice),
                    isTokenDeposit,
                    depositLamports
                )
                .accounts(accountsObj)
                .rpc();

            // Start progress bar just before finalizing the game
            setShouldStartProgress(true);
            setStartTime(performance.now());

            const subscriptionId = connection.onSignature(
                txSignature,
                async (result) => {
                    if (result.err) {
                        setError("Transaction failed. Please try again.");
                        setGameState("betting");
                        setLoading(false);
                    } else {
                        try {

                            // Finalize the game using our service
                            const gameResponse = await finalizeGame(
                                wallet.publicKey!.toString(), 
                                gameIdNumber
                            ) as unknown as GameResponse;
                            
                            // Convert API response to our internal Game format
                            const game: Game = {
                                _id: gameResponse._id,
                                gameIdNumber: gameResponse.gameIdNumber,
                                username: gameResponse.username.length > 10 ? gameResponse.username.slice(0, 10) + '...' : gameResponse.username,
                                gameResult: {
                                    houseChoice: gameResponse.gameResult.house_choice,
                                    house_choice: gameResponse.gameResult.house_choice,
                                    result: gameResponse.gameResult.result as 'Win' | 'Lose' | 'Tie',
                                    payout: gameResponse.gameResult.payout
                                },
                                status: gameResponse.status,
                                createdAt: new Date(gameResponse.createdAt)
                            };
                            
                            // Ensure we have a valid house choice before setting state
                            const houseChoiceValue = gameResponse.gameResult.house_choice;
                            if (houseChoiceValue !== undefined) {
                                setHouseChoice(houseChoiceValue);
                            }
                            setCurrentGame(game);
                            setGameState("result");
                            
                            // Update the user's volume after the game
                            loadUserData();
                            
                            // Refresh games and stats in contexts
                            await refreshGames();
                            await refreshStats();
                            await refreshUser();
                            
                        } catch (err) {
                            console.error("Error finalizing game:", err);
                            setError("Error finalizing game. Please try again.");
                            setGameState("betting");
                        } finally {
                            setLoading(false);
                        }
                    }
                    connection.removeSignatureListener(subscriptionId);
                },
                "confirmed"
            );
        } catch (err: any) {
            console.error("Error depositing bet:", err);
            setError("Transaction failed. Please try again.");
            setLoading(false);
            setGameState("betting");
            setShowGameFlow(false);
        }
    };

    // Function to show toast
    const showToast = (message: string, type: 'success' | 'error' | 'info') => {
        setToast({ message, type });
        // Auto-hide after 3 seconds
        setTimeout(() => setToast(null), 3000);
    };

    if (settingsError) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="flex flex-col items-center gap-4 text-center p-6 bg-red-900 bg-opacity-30 rounded-xl">
                    <p className="text-white text-lg">{settingsError}</p>
                    <button 
                        onClick={loadGameSettings}
                        className="px-4 py-2 bg-[#9509B7] rounded-md hover:bg-purple-700 transition-colors"
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    if (settingsLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="flex flex-col items-center gap-4">
                    <div className="w-12 h-12 border-t-2 border-pink border-opacity-50 rounded-full animate-spin"></div>
                    <p className="text-white text-lg">Loading game settings...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-200px)]">
            {/* Toast Container */}
            <div className="toast toast-top toast-end">
                {toast && (
                    <div className={`alert ${toast.type === 'success' ? 'alert-success' : toast.type === 'error' ? 'alert-error' : 'alert-info'}`}>
                        <span>{toast.message}</span>
                    </div>
                )}
            </div>
            
            
            {showGameFlow && (
                <GameFlow
                    userChoice={userChoice}
                    houseChoice={houseChoice}
                    gameState={gameState}
                    amount={betAmount}
                    game={currentGame}
                    isBetFormHidden={true}
                    onContinue={handleContinue}
                    shouldStartProgress={shouldStartProgress}
                    startTime={startTime}
                />
            )}

            {gameState !== "countdown" && gameState !== "result" && (
                <div className={classnames(
                    "betform-bg w-full max-w-[600px] p-4 rounded-xl flex flex-col text-white mx-6",
                    showGameFlow ? "mt-4" : "mt-12 mb-8"
                )}>
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl font-bold mb-4">{t('betting.placeBet')}</h2>

                        <div className="flex gap-2 mb-6">
                            <button
                                type="button"
                                className={classnames(
                                    "flex-1 px-4 py-2 rounded-md",
                                    playMode === "SOL" ? "bg-[#9509B7]" : "bg-[#462579]"
                                )}
                                onClick={() => handlePlayModeChange("SOL")}
                            >
                                {t('betting.modes.sol')}
                            </button>
                            <button
                                type="button"
                                className={classnames(
                                    "flex-1 px-4 py-2 rounded-md",
                                    playMode === "TOKEN" ? "bg-[#9509B7]" : "bg-[#462579]"
                                )}
                                onClick={() => handlePlayModeChange("TOKEN")}
                            >
                                {t('betting.modes.riskFree')}
                            </button>
                        </div>

                        <label htmlFor="betAmount" className="block text-lg mb-2">
                            {t('betting.amount')}
                        </label>
                        <div className="flex items-center gap-3 bg-[#2D1651] rounded-lg p-3 mb-4">
                            <img
                                src="/img/sol_logo.png"
                                alt={playMode === "SOL" ? t('betting.solanaLogo') : t('betting.tokenLogo')}
                                className="w-8 h-8 object-contain"
                            />
                            <span className="whitespace-nowrap text-xl lg:mr-12">
                                {playMode === "SOL" ? t('betting.solanaSol') : t('betting.freePlay')}
                            </span>
                            <input
                                type="text"
                                id="betAmount"
                                value={betAmount > 0 ? betAmount : ""}
                                onChange={(e) => {
                                    const val = parseFloat(e.target.value);
                                    validateSetBetAmount(isNaN(val) ? 0 : val);
                                }}
                                placeholder={t('betting.enterAmount')}
                                className="flex-1 px-3 py-2 rounded-md bg-[#FFFFFF1A] text-white focus:outline-none w-full"
                            />
                        </div>

                        <div className="grid grid-cols-6 gap-2 mb-6">
                            {[0.01, 0.1, 0.25, 1, 5].map((val) => (
                                <button
                                    key={val}
                                    type="button"
                                    onClick={() => validateSetBetAmount(betAmount + val)}
                                    className="px-2 py-2 md:px-3 md:py-2 bg-[#462579] rounded-md text-xs md:text-sm hover:bg-[#9509B7] text-center"
                                >
                                    +{val}
                                </button>
                            ))}
                            <button
                                type="button"
                                onClick={() => validateSetBetAmount(0)}
                                className="px-2 py-2 md:px-3 md:py-2 bg-[#462579] rounded-md text-xs md:text-sm hover:bg-[#9509B7] text-center"
                            >
                                {t('betting.clear')}
                            </button>
                        </div>

                        <label htmlFor="betChoice" className="block text-lg mb-2">
                            {t('betting.chooseMove')}
                        </label>
                        <div className="grid grid-cols-3 gap-3 mb-6">
                            {[
                                {choice: 0, label: t('betting.moves.rock'), img: "/img/rock.png"},
                                {choice: 1, label: t('betting.moves.paper'), img: "/img/paper.png"},
                                {choice: 2, label: t('betting.moves.scissors'), img: "/img/scissors.png"},
                            ].map(({choice, label, img}) => (
                                <button
                                    key={choice}
                                    type="button"
                                    onClick={() => handleMoveChoice(choice)}
                                    className={classnames(
                                        "flex flex-row items-center justify-center px-2 py-2 md:px-4 md:py-3 rounded-md gap-1 md:gap-2 text-xs md:text-base",
                                        userChoice === choice ? "bg-[#9509B7]" : "bg-[#462579]"
                                    )}
                                >
                                    <img src={img} alt={label} className="w-auto h-8 md:h-12"/>
                                    {label}
                                </button>
                            ))}
                        </div>

                        {error && <p className="text-red-400 mb-3">{error}</p>}

                        <button
                            type="submit"
                            disabled={loading}
                            className={classnames(
                                "w-full px-4 py-3 rounded-md text-lg font-semibold",
                                loading ? "bg-[#9509B7] cursor-not-allowed" : "bg-[#462579] hover:bg-[#9509B7]"
                            )}
                        >
                            {loading ? t('betting.placingBet') : t('betting.placeBet')}
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default BetForm;
