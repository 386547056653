export function openModal(modalId: string) {
    return () => {
        const modal = document.getElementById(modalId);
        if (modal) {
            // Add class to body to indicate modal is open
            document.body.classList.add('modal-open');
            
            // For mobile: position modal at the center, absolute positioning for keyboard
            if (window.innerWidth <= 768) {
                const modalContent = modal.querySelector('.modal-box');
                if (modalContent) {
                    // Reset any previous inline styles
                    modalContent.setAttribute('style', '');
                }
            }
            
            // Using any type assertion as HTMLDialogElement might not be available in all TypeScript versions
            (modal as any).showModal();
        }
    }
}
export function generateRandomUsername() {
    const adjectives = [
        "sleepy", "happy", "quiet", "loud", "proud", "brave", "dark", "light", "golden", "silver",
        "swift", "clever", "mighty", "gentle", "wild", "fierce", "wise", "calm", "bold", "mystic",
        "royal", "ancient", "cosmic", "crystal", "emerald", "ruby", "sapphire", "jade", "crimson", "azure"
    ];
    const nouns = [
        "lion", "tiger", "bear", "cat", "dog", "fox", "wolf", "panda", "eagle", "hawk",
        "dragon", "phoenix", "unicorn", "griffin", "falcon", "raven", "serpent", "dolphin", "panther", "jaguar",
        "warrior", "knight", "sage", "wizard", "hunter", "ranger", "scout", "guardian", "sentinel", "champion"
    ];

    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    // Increased range to 10000 for more variety
    const randomNumber = Math.floor(Math.random() * 10000);

    return `${randomAdjective}-${randomNoun}-${randomNumber}`;
}

export function truncateString(str: string, maxLength: number, suffix = "..."): string {
    if (!str) return str;
    if (str.length <= maxLength) return str;
    return str.slice(0, maxLength - suffix.length) + suffix;
}

/**
 * Formats a number with the specified number of decimal places
 * @param value - The number to format
 * @param decimalPlaces - Number of decimal places (default: 2)
 * @returns Formatted number as a string
 */
export function formatNumber(value: number | null | undefined, decimalPlaces: number = 2): string {
    if (value === null || value === undefined) return '';
    const fixed = value.toFixed(decimalPlaces);
    return fixed.endsWith('.00') ? fixed.slice(0, -3) : fixed.replace(/\.?0+$/, '');
}

/**
 * Detects if the current device is running iOS
 * @returns {boolean} True if the device is running iOS, false otherwise
 */
export function isIOS(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
}
