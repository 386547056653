import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getLuckyNumbers } from '../services/globalStatsService';

interface Stats {
    luckyNumbers: Record<string, number>;
}

interface StatsContextType {
    stats: Stats;
    isLoading: boolean;
    error: string | null;
    refreshStats: () => Promise<void>;
}

const defaultStats: Stats = {
    luckyNumbers: {
        rock: 0,
        paper: 0,
        scissors: 0
    }
};

const StatsContext = createContext<StatsContextType>({
    stats: defaultStats,
    isLoading: false,
    error: null,
    refreshStats: async () => {}
});

export const useStatsContext = () => useContext(StatsContext);

export const StatsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [stats, setStats] = useState<Stats>(defaultStats);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchStats = useCallback(async () => {
        setIsLoading(true);
        try {
            // Fetch lucky numbers
            const luckyNumbers = await getLuckyNumbers();
            
            setStats({
                luckyNumbers
            });
            
            setError(null);
        } catch (error) {
            console.error("Error fetching stats:", error);
            setError("Failed to fetch stats");
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Function to manually refresh stats
    const refreshStats = useCallback(async () => {
        await fetchStats();
    }, [fetchStats]);

    useEffect(() => {
        fetchStats();
        
        // Set up interval to refresh stats every 5 seconds
        const interval = setInterval(() => {
            fetchStats();
        }, 5000);
        
        return () => clearInterval(interval);
    }, [fetchStats]);

    return (
        <StatsContext.Provider value={{ stats, isLoading, error, refreshStats }}>
            {children}
        </StatsContext.Provider>
    );
}; 