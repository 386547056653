import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    
    backend: {
      loadPath: '/translations/{{lng}}.json',
    },
    
    interpolation: {
      escapeValue: false,
    },

    supportedLngs: ['en', 'es', 'zh'],
    
    // Common namespaces shared across components
    ns: ['common', 'auth', 'profile', 'stats'],
    defaultNS: 'common',
  });

export default i18n; 