import { post, get } from './apiService';
import { User } from './userService';

/**
 * Possible game choices
 */
export type GameChoice = 'rock' | 'paper' | 'scissors';

/**
 * Game outcome types
 */
export type GameOutcome = 'win' | 'lose' | 'draw';

/**
 * Interface for a completed game
 */
export interface Game {
  _id: string;
  userId: string;
  playerChoice: GameChoice;
  houseChoice: GameChoice;
  betAmount: number;
  outcome: GameOutcome;
  payout: number;
  timestamp: Date;
  username?: string; // Optional username for display purposes
  walletAddress?: string; // Optional wallet address for display purposes
}

/**
 * Interface for game result
 */
export interface GameResult {
  game: Game;
  user: User;
  isNewHighScore?: boolean;
}

/**
 * Interface for game statistics
 */
export interface GameStats {
  totalGames: number;
  wins: number;
  losses: number;
  draws: number;
  winRate: number;
  totalVolume: number;
  netProfit: number;
  mostPlayedChoice: GameChoice;
  mostSuccessfulChoice: GameChoice;
}

/**
 * Interface for legacy format game data used in live feed
 */
export interface LegacyGameResult {
  house_choice: number;
}

/**
 * Extended game interface with additional result data for older components
 */
export interface GameWithResult extends Game {
  gameResult?: LegacyGameResult;
}

/**
 * Play a game of Rock, Paper, Scissors
 * @param userId User ID of the player
 * @param choice Player's choice (rock, paper, scissors)
 * @param betAmount Amount of tokens bet on the game
 * @returns Promise resolving to the game result
 */
export const playGame = async (
  userId: string,
  choice: GameChoice,
  betAmount: number
): Promise<GameResult> => {
  try {
    const response = await post('/api/games/play', {
      userId,
      choice,
      betAmount
    });
    
    return response.data;
  } catch (error) {
    console.error('Error playing game:', error);
    throw error;
  }
};

/**
 * Create a new game record
 * @param userPubkey The user's public key
 * @returns Promise resolving to the created game ID and number
 */
export const createGame = async (userPubkey: string): Promise<{ gameId: string, gameIdNumber: number }> => {
  try {
    const response = await post('/api/games/create', { userPubkey });
    return response.data;
  } catch (error) {
    console.error('Error creating game:', error);
    throw error;
  }
};

/**
 * Finalize a game on the blockchain
 * @param userPubkey The user's public key
 * @param gameIdNumber The game ID number to finalize
 * @returns Promise resolving to the finalized game
 */
export const finalizeGame = async (userPubkey: string, gameIdNumber: number): Promise<Game> => {
  try {
    const response = await post('/api/games/finalize', { userPubkey, gameIdNumber });
    return response.data;
  } catch (error) {
    console.error('Error finalizing game:', error);
    throw error;
  }
};

/**
 * Get user's current volume limits
 * @param walletAddress The user's wallet address
 * @returns Promise resolving to the user's hourly and daily volume
 */
export const getUserVolume = async (walletAddress: string): Promise<{ hourly: number, daily: number }> => {
  try {
    const response = await get(`/api/games/user-volume/${walletAddress}`);
    return response.data;
  } catch (error) {
    console.error('Error getting user volume:', error);
    return { hourly: 0, daily: 0 };
  }
};

/**
 * Get the most recent games for a user
 * @param userId User ID to get games for
 * @param limit Maximum number of games to return
 * @returns Promise resolving to an array of games
 */
export const getUserGames = async (userId: string, limit = 10): Promise<Game[]> => {
  try {
    const response = await get(`/api/games/user/${userId}`, {
      params: { limit }
    });
    
    return response.data.games;
  } catch (error) {
    console.error('Error getting user games:', error);
    return [];
  }
};

/**
 * Legacy function - Get games by player ID
 * @param playerId The player's ID to get games for
 * @returns Promise resolving to an array of games
 */
export const getGamesByPlayerId = async (playerId: string): Promise<Game[]> => {
  // This function is for backward compatibility - redirect to getUserGames
  return getUserGames(playerId);
};

/**
 * Get game statistics for a user
 * @param userId User ID to get statistics for
 * @returns Promise resolving to the user's game statistics
 */
export const getUserGameStats = async (userId: string): Promise<GameStats> => {
  try {
    const response = await get(`/api/games/stats/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting user game stats:', error);
    // Return default stats
    return {
      totalGames: 0,
      wins: 0,
      losses: 0,
      draws: 0,
      winRate: 0,
      totalVolume: 0,
      netProfit: 0,
      mostPlayedChoice: 'rock',
      mostSuccessfulChoice: 'rock'
    };
  }
};

/**
 * Get games with optional parameters for filtering and formatting
 * @param limit Maximum number of games to return
 * @param includeLegacyFormat Whether to include legacy format data (for older components)
 * @returns Promise resolving to an array of games
 */
export const getGames = async (limit = 10, includeLegacyFormat = false): Promise<Game[] | GameWithResult[]> => {
  try {
    const response = await get('/api/games', {
      params: { limit }
    });
    
    const games = response.data.games as Game[];
    
    if (!includeLegacyFormat) {
      return games;
    }
    
    // Transform the results to include the legacy format required by older components
    return games.map(game => ({
      ...game,
      gameResult: {
        house_choice: game.houseChoice === 'rock' ? 0 : 
                      game.houseChoice === 'paper' ? 1 : 2
      }
    }));
  } catch (error) {
    console.error('Error getting games:', error);
    
    // Generate mock data for development, similar to existing mock data in getLiveFeedGames
    const mockGames: GameWithResult[] = [];
    
    // Mock usernames for development
    const usernames = ['CryptoKing', 'DiamondHands', 'MoonBoy', 'Hodler', 'SatoshiFan', 'BlockchainBro'];
    
    for (let i = 0; i < limit; i++) {
      // Generate random choices and results
      const randomPlayerChoice = Math.floor(Math.random() * 3);
      const randomHouseChoice = Math.floor(Math.random() * 3);
      const userId = `wallet${Math.floor(Math.random() * 1000000)}`;
      const betAmount = parseFloat((Math.random() * 5 + 0.1).toFixed(2));
      
      const playerChoices: GameChoice[] = ['rock', 'paper', 'scissors'];
      const houseChoices: GameChoice[] = ['rock', 'paper', 'scissors'];
      
      // Determine outcome
      let outcome: GameOutcome;
      if (randomPlayerChoice === randomHouseChoice) {
        outcome = 'draw';
      } else if (
        (randomPlayerChoice === 0 && randomHouseChoice === 2) ||
        (randomPlayerChoice === 1 && randomHouseChoice === 0) ||
        (randomPlayerChoice === 2 && randomHouseChoice === 1)
      ) {
        outcome = 'win';
      } else {
        outcome = 'lose';
      }
      
      // Calculate payout
      const payout = outcome === 'win' ? betAmount * 2 : (outcome === 'draw' ? betAmount : 0);
      
      mockGames.push({
        _id: `mock-${i}`,
        userId,
        playerChoice: playerChoices[randomPlayerChoice],
        houseChoice: houseChoices[randomHouseChoice],
        betAmount,
        outcome,
        payout,
        timestamp: new Date(Date.now() - i * 60000 - Math.random() * 3600000), // Random time in the past hour
        username: usernames[Math.floor(Math.random() * usernames.length)],
        gameResult: {
          house_choice: randomHouseChoice
        }
      });
    }
    
    // Sort by timestamp, newest first
    return mockGames.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  }
};

/**
 * Get recent global games
 * @param limit Maximum number of games to return
 * @returns Promise resolving to an array of recent games
 */
export const getRecentGames = async (limit = 10): Promise<Game[]> => {
  // Use the new consolidated function
  return getGames(limit) as Promise<Game[]>;
};

/**
 * Get latest games for display in components
 * @param limit Maximum number of games to return
 * @returns Promise resolving to an array of games with results
 */
export const getLatestGames = async (limit = 5): Promise<GameWithResult[]> => {
  // Use the new consolidated function with legacy format
  return getGames(limit, true) as Promise<GameWithResult[]>;
};

/**
 * Get completed games for sidebar display
 * @param limit Maximum number of games to return
 * @returns Promise resolving to an array of completed games
 */
export const getCompletedGames = async (limit = 10): Promise<Game[]> => {
  // Use the new consolidated function
  return getGames(limit) as Promise<Game[]>;
};

/**
 * Get games for the live feed with additional legacy data format
 * @param limit Maximum number of games to return
 * @returns Promise resolving to an array of games with legacy-format data
 */
export const getLiveFeedGames = async (limit = 20): Promise<GameWithResult[]> => {
  // Use the new consolidated function with legacy format
  return getGames(limit, true) as Promise<GameWithResult[]>;
};

/**
 * Calculate the potential payout for a given bet amount based on current game settings
 * @param betAmount Amount of tokens bet on the game
 * @returns The potential payout amount if the player wins
 */
export const calculatePotentialPayout = (betAmount: number): number => {
  // Assuming a 2x multiplier for a win (minus house edge)
  const houseEdgePercent = 3; // 3% house edge
  const multiplier = 2 * (1 - houseEdgePercent / 100);
  return parseFloat((betAmount * multiplier).toFixed(2));
};

/**
 * Get the current volume limits
 * @returns Promise resolving to hourly and daily volume limits
 */
export const getVolumeLimits = async (): Promise<{ hourly: number, daily: number }> => {
  try {
    const response = await get('/api/games/volume-limits');
    return response.data;
  } catch (error) {
    console.error('Error getting volume limits:', error);
    // Return default limits
    return {
      hourly: 100,
      daily: 1000
    };
  }
}; 