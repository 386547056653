/**
 * Global Stats Service
 * 
 * Replaces Meteor's GlobalStatsCollection for game statistics
 */

import { get, post } from './apiService';

/**
 * Global statistics interface
 */
export interface GlobalStats {
  _id: string;
  totalPlays: number;
  totalVolume: number;
  totalReferralPaid: number;
  winCount: number;
  drawCount: number;
  loseCount: number;
  houseChoices: {
    rock: number;
    paper: number;
    scissors: number;
  };
  updatedAt?: Date;
}

/**
 * Fetch global game statistics
 * @returns Promise resolving to the global statistics
 */
export const getGlobalStats = async (): Promise<GlobalStats> => {
  try {
    const response = await get<GlobalStats>('/api/global-stats');
    
    // Return the direct response data as the server returns the stats object directly
    return response.data;
  } catch (error) {
    console.error('Error fetching global stats:', error);
    // Return default values if there's an error
    return {
      _id: 'global',
      totalPlays: 0,
      totalVolume: 0,
      totalReferralPaid: 0,
      winCount: 0,
      drawCount: 0,
      loseCount: 0,
      houseChoices: {
        rock: 0,
        paper: 0,
        scissors: 0
      }
    };
  }
};

/**
 * Recalculate global game statistics (admin only)
 * @returns Promise resolving to the recalculated statistics
 */
export const recalculateGlobalStats = async (): Promise<GlobalStats> => {
  try {
    const response = await post<GlobalStats>('/api/global-stats/recalculate', {});
    
    return response.data;
  } catch (error) {
    console.error('Error recalculating global stats:', error);
    throw error;
  }
};

/**
 * Get lucky numbers (most common house choices)
 * @returns Promise resolving to the percentage occurrence of each choice
 */
export const getLuckyNumbers = async (): Promise<Record<string, number>> => {
  const stats = await getGlobalStats();
  
  // Return the percentage occurrence of each choice
  const total = stats.totalPlays || 1; // Avoid division by zero
  
  return {
    rock: Math.round((stats.houseChoices.rock / total) * 100),
    paper: Math.round((stats.houseChoices.paper / total) * 100),
    scissors: Math.round((stats.houseChoices.scissors / total) * 100)
  };
}; 