import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { openModal } from "../helpers";
import { useAvatarUrl } from "../hooks/useAvatarUrl";
import LanguageSwitcher from "./LanguageSwitcher";

const Header: React.FC = () => {
    const { t } = useTranslation();
    const wallet = useWallet();
    const { setVisible } = useWalletModal();
    const { user } = useUser();

    // Track username in local state to ensure updates
    const [displayUsername, setDisplayUsername] = useState<string>("");

    // Update local state when user context changes
    useEffect(() => {
        if (user?.username) {
            setDisplayUsername(user.username);
        }
    }, [user]);

    // Use the avatar hook with user?.avatarUrl as the dependency
    const { avatarUrl, isLoading } = useAvatarUrl(user?.avatarUrl);

    const closeDrawer = () => {
        const drawerCheckbox = document.getElementById('my-drawer') as HTMLInputElement;
        if (drawerCheckbox) {
            drawerCheckbox.checked = false;
        }
    };

    const handleModalOpen = (modalId: string) => {
        closeDrawer();
        openModal(modalId)();
    };

    const handleDisconnect = () => {
        closeDrawer();
        wallet.disconnect();
    };

    const handleConnectWallet = () => {
        closeDrawer();
        setVisible(true);
    };

    const handleReferralClick = (e: React.MouseEvent) => {
        if (!wallet.connected) {
            e.preventDefault();
            closeDrawer();
            openModal("referral-modal")();
        }
    };

    return (
        <>
            <header className="bg-black text-white px-6 h-16 flex items-center">
                <Link to="/" className="flex items-center gap-2">
                    <img
                        src="/img/logo.png"
                        alt={t('header.logoAlt')}
                        className="w-6 h-6 rounded-full"
                    />
                    <span className="text-xl font-semibold">Play RPS</span>
                </Link>

                {/* Center: Nav links - Hidden on mobile */}
                <nav className="flex-1 hidden lg:flex items-center justify-center gap-6 text-base font-medium">
                    <Link
                        to="/lucky-numbers"
                        className="hover:text-purple-400 relative after:absolute after:w-full after:h-[2px] after:bg-purple-400 after:bottom-[-2px] after:left-0 after:scale-x-0 hover:after:scale-x-100 after:transition-transform"
                    >
                        {t('header.nav.luckyNumbers')}
                    </Link>
                    <Link
                        to="/leaderboard"
                        className="lg:block hover:text-purple-400 relative after:absolute after:w-full after:h-[2px] after:bg-purple-400 after:bottom-[-2px] after:left-0 after:scale-x-0 hover:after:scale-x-100 after:transition-transform"
                    >
                        {t('header.nav.leaderboard')}
                    </Link>
                    <Link
                        to="/referrals"
                        className="lg:block hover:text-purple-400 relative after:absolute after:w-full after:h-[2px] after:bg-purple-400 after:bottom-[-2px] after:left-0 after:scale-x-0 hover:after:scale-x-100 after:transition-transform"
                        onClick={handleReferralClick}
                    >
                        {t('header.nav.referrals')}
                    </Link>
                    &nbsp;
                </nav>

                <div className="flex justify-end items-center ml-auto">
                    <LanguageSwitcher />
                    {wallet?.connected && (
                        <div className="hidden lg:flex items-center">
                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} role="button" className="btn-text text-white">
                                    <img
                                        src={isLoading ? "/img/user_placeholder.png" : (avatarUrl || "/img/user_placeholder.png")}
                                        alt={t('header.profileAlt')}
                                        className="w-8 h-8 rounded-full object-cover"
                                    />
                                    <span className="text-base font-medium truncate max-w-[95px] text-white">
                                        {displayUsername || user?.username}
                                    </span>
                                </div>
                                <ul className="menu dropdown-content bg-[#2D1651] rounded-box z-[1] w-52 p-2 shadow">
                                    <li>
                                        <button onClick={openModal("profile-modal")}>
                                            {t('header.menu.profile')}
                                        </button>
                                    </li>
                                    <li>
                                        <Link to="/personal-stats">{t('header.menu.myStats')}</Link>
                                    </li>
                                    <li>
                                        <button onClick={openModal("howtoplay-modal")}>
                                            {t('header.menu.howToPlay')}
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={openModal("faq-modal")}>
                                            {t('header.menu.faq')}
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={openModal("referral-modal")}>
                                            {t('header.menu.referralProgram')}
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => wallet.disconnect()}>
                                            {t('header.menu.disconnectWallet')}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    )}

                    {/* Mobile Drawer Button */}
                    <div className="lg:hidden">
                        <label htmlFor="my-drawer" className="drawer-button">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </label>
                    </div>
                </div>
            </header>

            {/* Drawer Component - Outside header */}
            <div className="drawer drawer-end">
                <input id="my-drawer" type="checkbox" className="drawer-toggle" />
                <div className="drawer-side z-50">
                    <label htmlFor="my-drawer" className="drawer-overlay"></label>
                    <ul className="menu p-4 w-80 min-h-full bg-[#2D1651] text-white">
                        <li>
                            <Link to="/lucky-numbers" className="text-lg" onClick={closeDrawer}>
                                {t('header.nav.luckyNumbers')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/leaderboard" className="text-lg" onClick={closeDrawer}>
                                {t('header.nav.leaderboard')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/referrals" className="text-lg" onClick={(e) => {
                                handleReferralClick(e);
                                if (wallet.connected) {
                                    closeDrawer();
                                }
                            }}>
                                {t('header.nav.referrals')}
                            </Link>
                        </li>
                        {!wallet?.connected && (
                            <>
                                <div className="divider"></div>
                                <li>
                                    <button onClick={handleConnectWallet} className="text-lg bg-pink py-2 rounded-md">
                                        Connect Wallet
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleModalOpen("howtoplay-modal")} className="text-lg">
                                        {t('header.menu.howToPlay')}
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleModalOpen("faq-modal")} className="text-lg">
                                        {t('header.menu.faq')}
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleModalOpen("referral-modal")} className="text-lg">
                                        {t('header.menu.referralProgram')}
                                    </button>
                                </li>
                            </>
                        )}
                        {wallet?.connected && (
                            <>
                                <div className="divider"></div>
                                <li>
                                    <button onClick={() => handleModalOpen("profile-modal")} className="text-lg">
                                        {t('header.menu.profile')}
                                    </button>
                                </li>
                                <li>
                                    <Link to="/personal-stats" className="text-lg" onClick={closeDrawer}>
                                        {t('header.menu.myStats')}
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={() => handleModalOpen("howtoplay-modal")} className="text-lg">
                                        {t('header.menu.howToPlay')}
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleModalOpen("faq-modal")} className="text-lg">
                                        {t('header.menu.faq')}
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleModalOpen("referral-modal")} className="text-lg">
                                        {t('header.menu.referralProgram')}
                                    </button>
                                </li>
                                <li>
                                    <button onClick={handleDisconnect} className="text-lg">
                                        {t('header.menu.disconnectWallet')}
                                    </button>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Header;
