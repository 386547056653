import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useUser } from "../../context/UserContext";
import { updateUserProfile } from "../../services/userService";
import AvatarUpload from "../AvatarUpload";

export default function ProfileModalContent() {
    const { t } = useTranslation();
    const wallet = useWallet();
    const { user, refreshUser } = useUser();
    const inputRef = useRef<HTMLInputElement>(null);
    const modalDialogRef = useRef<HTMLDialogElement>(null);

    const [name, setName] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [avatarChanged, setAvatarChanged] = useState(false);
    const [newAvatarPath, setNewAvatarPath] = useState<string | null>(null);

    // Setup dialog closing event listener
    useEffect(() => {
        const handleCloseDialog = () => {
            document.body.classList.remove('keyboard-open', 'modal-open');
        };

        const dialogElement = modalDialogRef.current;
        if (dialogElement) {
            dialogElement.addEventListener('close', handleCloseDialog);
        }

        return () => {
            if (dialogElement) {
                dialogElement.removeEventListener('close', handleCloseDialog);
            }
        };
    }, []);

    // Handle keyboard visibility
    useEffect(() => {
        const handleResize = () => {
            if (!modalDialogRef.current?.open) return;
            
            const viewportHeight = window.innerHeight;
            // Detect if keyboard is likely open (viewport height significantly reduced)
            if (viewportHeight < window.outerHeight * 0.75) {
                document.body.classList.add('keyboard-open');
            } else {
                document.body.classList.remove('keyboard-open');
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Handle input focus and scroll
    const handleInputFocus = () => {
        // Add keyboard-open class
        document.body.classList.add('keyboard-open');
        
        // Scroll to input after a short delay
        setTimeout(() => {
            if (inputRef.current) {
                // Scroll the input into view and center it
                inputRef.current.scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'center'
                });
            }
        }, 300);
    };

    // Handle input blur
    const handleInputBlur = () => {
        // Remove keyboard-open class after delay
        setTimeout(() => {
            document.body.classList.remove('keyboard-open');
        }, 300);
    };

    // Prefill the form when user data is available.
    useEffect(() => {
        if (user) {
            setName(user.username || "");
            // Reset avatar change flag when user data changes
            setAvatarChanged(false);
            setNewAvatarPath(null);
        }
    }, [user]);

    const closeModal = () => {
        document.body.classList.remove('keyboard-open', 'modal-open');
        const modal = document.getElementById("profile-modal") as HTMLDialogElement;
        if (modal) modal.close();
    };

    async function save() {
        if (user && user.walletAddress) {
            setIsSaving(true);
            try {
                // Create updates object with the current username
                const updates: Record<string, any> = { username: name };
                
                // If avatar was changed, include the new path
                // Otherwise, explicitly include the existing avatar to preserve it
                if (avatarChanged && newAvatarPath) {
                    console.log("Saving new avatar path (not URL):", newAvatarPath);
                    updates.avatarUrl = newAvatarPath;
                } else if (user.avatarUrl) {
                    // Explicitly preserve the existing avatar when only updating username
                    updates.avatarUrl = user.avatarUrl;
                }
                
                // Update user in the backend
                try {
                    const updatedUser = await updateUserProfile(user.walletAddress, updates);
                    console.log("Profile updated successfully:", updatedUser);
                    
                    // Update local user state optimistically
                    // This ensures the UI updates even if refreshUser has auth issues
                    if (user) {
                        // Apply the updates locally if refreshUser fails
                        const localUserUpdate = {
                            ...user,
                            username: name,
                            avatarUrl: avatarChanged && newAvatarPath ? newAvatarPath : user.avatarUrl
                        };
                        
                        // Force a refresh of the user context
                        await refreshUser();
                    }
                } catch (updateError) {
                    console.error("Error updating profile in backend:", updateError);
                    throw updateError;
                }
                
                // Add a small delay to ensure state updates are processed
                await new Promise(resolve => setTimeout(resolve, 100));
                
                // Reset avatar change state after successful save
                setAvatarChanged(false);
                
                // Close the modal after saving
                closeModal();
            } catch (error) {
                console.error("Error saving profile:", error);
            } finally {
                setIsSaving(false);
            }
        }
    }

    return (
        <dialog ref={modalDialogRef} id="profile-modal" className="modal">
            <div className="modal-box rps-modal-box">
                <button
                    className="absolute top-4 right-4 text-2xl leading-none"
                    onClick={closeModal}
                >
                    &times;
                </button>

                {/* Title */}
                <h2 className="text-center text-2xl font-bold mb-6">{t('profile.title')}</h2>

                {/* Avatar Upload */}
                <div className="flex flex-col items-center mb-6">
                    <AvatarUpload
                        currentAvatarUrl={avatarChanged ? newAvatarPath : user?.avatarUrl}
                        onAvatarUpdate={(objectPath) => {
                            console.log("Avatar updated with object path (not URL):", objectPath);
                            
                            // Only store the path if it's a valid string
                            if (objectPath && typeof objectPath === 'string' && objectPath.trim() !== '') {
                                setNewAvatarPath(objectPath);
                                setAvatarChanged(true);
                            } else {
                                console.error("Received invalid avatar path:", objectPath);
                            }
                        }}
                    />
                </div>

                {/* Username input */}
                <div className="mb-6 text-center">
                    <input
                        ref={inputRef}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        placeholder={t('profile.username')}
                        className="text-center bg-purple-dark rounded-md py-2 border-none focus:outline-none focus:ring-2 focus:ring-pink"
                    />
                </div>

                <form method="dialog" className="w-full">
                    <button
                        onClick={save}
                        disabled={isSaving}
                        className="bg-pink w-full py-3 rounded-md text-white font-semibold hover:opacity-90 disabled:opacity-70"
                    >
                        {isSaving ? t('common.saving') : t('common.save')}
                    </button>
                </form>
            </div>
        </dialog>
    );
}
