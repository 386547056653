import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    // Remove focus from the dropdown to close it
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const languages = [
    { code: 'en', label: 'English', display: 'EN' },
    { code: 'es', label: 'Español', display: 'ES' },
    { code: 'zh', label: '中文', display: '中文' }
  ];

  const currentLang = languages.find(lang => lang.code === i18n.language);

  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn btn-ghost m-1 gap-2" data-dropdown-toggle="language-dropdown">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
        </svg>
        <span className="text-sm font-medium">{currentLang?.display || 'EN'}</span>
      </div>
      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-[#2D1651] rounded-box w-32 mt-1" id="language-dropdown">
        {languages.map((lang) => (
          <li key={lang.code}>
            <button 
              type="button"
              onClick={() => changeLanguage(lang.code)}
              className={`text-white hover:text-purple-400 ${i18n.language === lang.code ? 'text-purple-400' : ''}`}
            >
              {lang.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSwitcher; 