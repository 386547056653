import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { User, initializeUser, getUserByWalletAddress, getPublicUserData } from '../services/userService';

interface UserContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  refreshUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType>({
  user: null,
  loading: false,
  error: null,
  refreshUser: async () => {}
});

export const useUser = () => useContext(UserContext);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const wallet = useWallet();

  const initUser = async () => {
    if (!wallet || !wallet.publicKey) return;
    
    const walletAddress = wallet.publicKey.toBase58();
    
    // If we've already initialized this wallet, don't do it again
    if (initialized && user?.walletAddress === walletAddress) {
      return;
    }
    
    setLoading(true);
    setError(null);
    setInitialized(true);

    try {
      // First check if the user already exists
      const existingUser = await getUserByWalletAddress(walletAddress);
      
      if (existingUser) {
        setUser(existingUser);
        console.log("Existing user loaded", existingUser);
        return;
      }
      
      // User doesn't exist, initialize them
      // Check URL for a referral code and save to localStorage if found
      const urlParams = new URLSearchParams(window.location.search);
      const referralFromUrl = urlParams.get("code");
      if (referralFromUrl) {
        localStorage.setItem("referralCode", referralFromUrl);
      }
      
      // Try to load referral code from localStorage
      const storedReferral = localStorage.getItem("referralCode");

      // Initialize the user
      const userResult = await initializeUser(walletAddress, storedReferral || undefined);
      setUser(userResult);
      console.log("User initialized successfully", userResult);
    } catch (err) {
      console.error("Error initializing user:", err);
      setError("Failed to initialize user");
    } finally {
      setLoading(false);
    }
  };

  const refreshUser = async () => {
    if (!wallet || !wallet.publicKey) return;
    
    const walletAddress = wallet.publicKey.toBase58();
    setLoading(true);
    
    try {
      // First try to get the complete user data (requires auth)
      try {
        const userResult = await getUserByWalletAddress(walletAddress);
        if (userResult) {
          setUser(userResult);
          return; // Exit early if successful
        }
      } catch (authErr) {
        console.log("Auth request failed, falling back to public data", authErr);
        // Fall through to public data if authentication fails
      }
      
      // If the authenticated request fails, try to get just the public data
      try {
        const publicData = await getPublicUserData(walletAddress);
        if (publicData && user) {
          // Merge the public data with existing user data
          setUser({
            ...user,
            username: publicData.username || user.username,
            avatarUrl: publicData.avatarUrl || user.avatarUrl
          });
        }
      } catch (publicErr) {
        console.error("Error fetching public user data:", publicErr);
      }
    } catch (err) {
      console.error("Error refreshing user data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (wallet?.publicKey) {
      initUser();
    } else {
      setUser(null);
      setInitialized(false);
    }
  }, [wallet?.publicKey?.toBase58()]);

  return (
    <UserContext.Provider value={{ user, loading, error, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider; 