import { User } from "./userService";
import { Game } from "./gameService";
import { API_BASE_URL } from "../../imports/utils";

/**
 * Interface for a leaderboard user entry with game statistics
 */
export interface LeaderboardUser {
  _id?: string;
  username: string;
  points: number;
  totalPlays: number;
  totalWins: number;
  volume: number;
  pnl: number;
  walletAddress?: string;
  lastGame?: {
    gameResult?: {
      deposit_amount: number;
      result: string;
    }
  };
}

/**
 * Interface for daily leaderboard stats
 */
export interface DailyLeaderboardUser {
  _id: string;
  date: string;
  username: string;
  walletAddress: string;
  volume: number;
  plays: number;
  pnl: number;
  winCount: number;
  drawCount: number;
  loseCount: number;
}

/**
 * Get leaderboard data sorted by PnL
 * @param options Configuration options for the leaderboard
 * @returns Promise resolving to array of leaderboard user entries
 */
export const getLeaderboard = async (options: {
  limit?: number;
  minPlays?: number;
  excludeZeroPnl?: boolean;
} = {}): Promise<LeaderboardUser[]> => {
  try {
    const { limit = 50, minPlays = 1, excludeZeroPnl = true } = options;
    
    // Ensure API_BASE_URL is defined
    if (!API_BASE_URL) {
      throw new Error('API_BASE_URL is not defined');
    }
    
    // Build URL with query parameters
    const url = new URL(`${API_BASE_URL}/api/leaderboard`);
    url.searchParams.append('limit', limit.toString());
    url.searchParams.append('minPlays', minPlays.toString());
    url.searchParams.append('excludeZeroPnl', excludeZeroPnl.toString());
    
    // Fetch leaderboard data from the server
    const response = await fetch(url.toString());
    
    if (!response.ok) {
      throw new Error(`Failed to fetch leaderboard: ${response.status} ${response.statusText}`);
    }
    
    const data = await response.json();
    
    // Map the server response to our LeaderboardUser interface
    return data.users.map((user: any) => ({
      _id: user._id,
      username: user.username || 'Anonymous',
      walletAddress: user.walletAddress,
      points: user.points || 0,
      totalPlays: user.totalPlays || 0,
      totalWins: user.winCount || 0,
      volume: user.totalVolume || 0,
      pnl: user.pnl || 0
    }));
  } catch (error) {
    console.error("Error fetching leaderboard data:", error);
    // Return empty array on error
    return [];
  }
};

/**
 * Get daily leaderboard data for a specific date
 * @param date Date in format YYYY-MM-DD
 * @param limit Maximum number of entries to retrieve
 * @returns Promise resolving to array of daily leaderboard entries
 */
export const getDailyLeaderboard = async (date: string, limit: number = 50): Promise<DailyLeaderboardUser[]> => {
  try {
    // Validate date format
    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      throw new Error('Invalid date format. Please use YYYY-MM-DD');
    }
    
    // Ensure API_BASE_URL is defined
    if (!API_BASE_URL) {
      throw new Error('API_BASE_URL is not defined');
    }
    
    // Build URL with query parameters
    const url = new URL(`${API_BASE_URL}/api/leaderboard/daily/${date}`);
    url.searchParams.append('limit', limit.toString());
    
    // Fetch daily leaderboard data from the server
    const response = await fetch(url.toString());
    
    if (!response.ok) {
      throw new Error(`Failed to fetch daily leaderboard: ${response.status} ${response.statusText}`);
    }
    
    const data = await response.json();
    
    // Return the user data
    return data.users;
  } catch (error) {
    console.error(`Error fetching daily leaderboard for ${date}:`, error);
    // Return empty array on error
    return [];
  }
};

/**
 * Get available dates for leaderboard
 * @returns Promise resolving to array of dates in format YYYY-MM-DD
 */
export const getLeaderboardDates = async (): Promise<string[]> => {
  try {
    // Ensure API_BASE_URL is defined
    if (!API_BASE_URL) {
      throw new Error('API_BASE_URL is not defined');
    }
    
    const response = await fetch(`${API_BASE_URL}/api/leaderboard/dates`);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch leaderboard dates: ${response.status} ${response.statusText}`);
    }
    
    const data = await response.json();
    return data.dates || [];
  } catch (error) {
    console.error("Error fetching leaderboard dates:", error);
    return [];
  }
};

/**
 * Fetch and aggregate leaderboard data for multiple dates
 * This is a client-side implementation that fetches each day separately
 * and aggregates the results, since we're working with the existing backend
 * 
 * @param startDate Start date in format YYYY-MM-DD
 * @param endDate End date in format YYYY-MM-DD
 * @param availableDates Array of available dates in the date range
 * @param limit Maximum number of entries to retrieve
 * @returns Promise resolving to array of aggregated leaderboard entries
 */
export const getDateRangeLeaderboard = async (
  startDate: string, 
  endDate: string,
  availableDates: string[],
  limit: number = 50
): Promise<DailyLeaderboardUser[]> => {
  try {
    // Filter dates that are within the requested range
    const datesInRange = availableDates.filter(
      date => date >= startDate && date <= endDate
    ).sort();
    
    // If no dates in range, return empty array
    if (datesInRange.length === 0) {
      return [];
    }
    
    // If only one date, just use the regular daily endpoint
    if (datesInRange.length === 1) {
      return await getDailyLeaderboard(datesInRange[0], limit);
    }
    
    // Fetch all dates in parallel
    const promises = datesInRange.map(date => getDailyLeaderboard(date));
    const results = await Promise.all(promises);
    
    // Aggregate the results
    const aggregatedMap: Record<string, DailyLeaderboardUser> = {};
    
    // Process each day's data
    for (const dailyUsers of results) {
      for (const user of dailyUsers) {
        if (!aggregatedMap[user.walletAddress]) {
          // Initialize new user entry
          aggregatedMap[user.walletAddress] = {
            _id: user.walletAddress,
            date: `${startDate} to ${endDate}`,
            username: user.username,
            walletAddress: user.walletAddress,
            volume: 0,
            plays: 0,
            pnl: 0,
            winCount: 0,
            drawCount: 0,
            loseCount: 0
          };
        }
        
        // Add stats from this day
        const aggregatedUser = aggregatedMap[user.walletAddress];
        aggregatedUser.volume += user.volume || 0;
        aggregatedUser.plays += user.plays || 0;
        aggregatedUser.pnl += user.pnl || 0;
        aggregatedUser.winCount += user.winCount || 0;
        aggregatedUser.drawCount += user.drawCount || 0;
        aggregatedUser.loseCount += user.loseCount || 0;
      }
    }
    
    // Convert to array and sort by PnL
    const aggregatedData = Object.values(aggregatedMap)
      .sort((a, b) => b.pnl - a.pnl)
      .slice(0, limit); // Apply the limit
    
    return aggregatedData;
  } catch (error) {
    console.error(`Error fetching leaderboard for date range ${startDate} to ${endDate}:`, error);
    return [];
  }
}; 