import * as React from "react";
import { StatCardProps } from "./types";

export const StatCard: React.FC<StatCardProps> = ({ title, value }) => {
  return (
    <article className="flex flex-col grow items-center px-16 py-12 md:px-16 md:py-12 px-6 py-6 w-full text-center text-white rounded-2xl border border-solid bg-purple-dark border-purple">
      <h3 className="text-lg md:text-2xl border-white">{title}</h3>
      <p className="mt-4 md:mt-11 text-3xl md:text-5xl">
        {value.toLocaleString()}
      </p>
    </article>
  );
};
