import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../helpers';
import { getLeaderboard, getDailyLeaderboard, getLeaderboardDates, getDateRangeLeaderboard, LeaderboardUser, DailyLeaderboardUser } from '../services/leaderboardService';

// Type for calendar days
interface CalendarDay {
    dayOfMonth: number;
    date: Date;
    isCurrentMonth: boolean;
    isToday: boolean;
    hasData: boolean;
}

// After the previous interface definitions
interface DateRange {
    startDate: string | null;
    endDate: string | null;
}

export default function LeaderboardPage() {
    const { t } = useTranslation();
    
    // Format a date as YYYY-MM-DD string in local timezone
    const formatDateString = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    
    // Get today's date in local timezone
    const today = formatDateString(new Date());
    
    const [leaderboard, setLeaderboard] = useState<LeaderboardUser[] | DailyLeaderboardUser[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showAllUsers, setShowAllUsers] = useState(false);
    // Replace single date with date range
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: today,
        endDate: today
    });
    // Add state for temporary selection
    const [pendingDateRange, setPendingDateRange] = useState<DateRange>({
        startDate: today,
        endDate: today
    });
    const [selectionInProgress, setSelectionInProgress] = useState<boolean>(false);
    const [availableDates, setAvailableDates] = useState<string[]>([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [calendarDays, setCalendarDays] = useState<CalendarDay[]>([]);
    const [viewMode, setViewMode] = useState<'all' | 'daily'>('daily');
    const [showCalendar, setShowCalendar] = useState(false);
    
    // Ref for calendar dropdown to handle clicks outside
    const calendarRef = useRef<HTMLDivElement>(null);
    
    // Format date display for the button
    const getDateDisplay = (): string => {
        if (viewMode === 'all') {
            return t('leaderboard.today', 'All Time');
        }
        
        if (dateRange.startDate) {
            if (dateRange.endDate && dateRange.startDate === dateRange.endDate) {
                // Single date display
                const [year, month, day] = dateRange.startDate.split('-').map(num => parseInt(num, 10));
                const date = new Date(year, month - 1, day, 12, 0, 0);
                return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
            } else if (dateRange.endDate) {
                // Date range display
                const [startYear, startMonth, startDay] = dateRange.startDate.split('-').map(num => parseInt(num, 10));
                const [endYear, endMonth, endDay] = dateRange.endDate.split('-').map(num => parseInt(num, 10));
                
                const startDate = new Date(startYear, startMonth - 1, startDay, 12, 0, 0);
                const endDate = new Date(endYear, endMonth - 1, endDay, 12, 0, 0);
                
                const startFormatted = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                const endFormatted = endDate.toLocaleDateString('en-US', { 
                    month: 'short', 
                    day: 'numeric', 
                    year: 'numeric' 
                });
                
                return `${startFormatted} - ${endFormatted}`;
            }
        }
        
        return t('leaderboard.today', 'Today');
    };
    
    // Close calendar when clicking outside
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                setShowCalendar(false);
            }
        }
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // Handle keyboard events for calendar
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (selectionInProgress) {
                    // Cancel range selection
                    setSelectionInProgress(false);
                    // Restore previous selection if there was one
                    if (dateRange.endDate) {
                        setPendingDateRange({
                            startDate: dateRange.startDate,
                            endDate: dateRange.endDate
                        });
                    }
                } else if (showCalendar) {
                    // Close calendar
                    setShowCalendar(false);
                    // Reset pending to match actual
                    setPendingDateRange({
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate
                    });
                }
            }
        };
        
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectionInProgress, showCalendar, dateRange]);
    
    // Generate calendar days for the current month
    useEffect(() => {
        const generateCalendarDays = () => {
            const year = currentMonth.getFullYear();
            const month = currentMonth.getMonth();
            
            // Get first day of the month
            const firstDayOfMonth = new Date(year, month, 1);
            const dayOfWeek = firstDayOfMonth.getDay();
            
            // Get the last day of the month
            const lastDayOfMonth = new Date(year, month + 1, 0);
            const daysInMonth = lastDayOfMonth.getDate();
            
            // Get the last day of the previous month
            const lastDayOfPrevMonth = new Date(year, month, 0);
            const daysInPrevMonth = lastDayOfPrevMonth.getDate();
            
            const days: CalendarDay[] = [];
            
            // Add days from previous month to start the calendar on Sunday
            const prevMonthDaysToShow = dayOfWeek === 0 ? 0 : dayOfWeek;
            for (let i = prevMonthDaysToShow - 1; i >= 0; i--) {
                const date = new Date(year, month - 1, daysInPrevMonth - i);
                days.push({
                    dayOfMonth: daysInPrevMonth - i,
                    date,
                    isCurrentMonth: false,
                    isToday: formatDateString(date) === today,
                    hasData: availableDates.includes(formatDateString(date))
                });
            }
            
            // Add days from current month
            for (let i = 1; i <= daysInMonth; i++) {
                const date = new Date(year, month, i);
                days.push({
                    dayOfMonth: i,
                    date, 
                    isCurrentMonth: true,
                    isToday: formatDateString(date) === today,
                    hasData: availableDates.includes(formatDateString(date))
                });
            }
            
            // Calculate how many days from next month we need to show
            const remainingCells = 7 - (days.length % 7);
            if (remainingCells < 7) {
                for (let i = 1; i <= remainingCells; i++) {
                    const date = new Date(year, month + 1, i);
                    days.push({
                        dayOfMonth: i,
                        date,
                        isCurrentMonth: false,
                        isToday: formatDateString(date) === today,
                        hasData: availableDates.includes(formatDateString(date))
                    });
                }
            }
            
            setCalendarDays(days);
        };
        
        generateCalendarDays();
    }, [currentMonth, availableDates, today]);
    
    // Fetch available dates for leaderboard
    useEffect(() => {
        const fetchAvailableDates = async () => {
            try {
                const dates = await getLeaderboardDates();
                setAvailableDates(dates);
            } catch (error) {
                console.error("Error fetching available dates:", error);
            }
        };
        
        fetchAvailableDates();
    }, []);
    
    // Helper function to check if a date is within a range
    const isDateInRange = (date: string, start: string | null, end: string | null): boolean => {
        if (!start || !end) return false;
        return date >= start && date <= end;
    };
    
    // New function to fetch aggregated leaderboard data for a date range
    const fetchDateRangeLeaderboard = async (startDate: string, endDate: string) => {
        setIsLoading(true);
        setError(null);
        try {
            // Check if there are dates in the range
            const dates = availableDates.filter(date => 
                date >= startDate && date <= endDate
            ).sort();
            
            if (dates.length === 0) {
                setLeaderboard([]);
                setIsLoading(false);
                return;
            }
            
            // Use the service function to get aggregated data
            const data = await getDateRangeLeaderboard(startDate, endDate, availableDates);
            setLeaderboard(data);
        } catch (error) {
            console.error("Error fetching leaderboard data for date range:", error);
            setError(t('leaderboard.error', 'Failed to load leaderboard data. Please try again later.'));
        } finally {
            setIsLoading(false);
        }
    };
    
    // Fetch leaderboard data (either all-time or daily/range)
    useEffect(() => {
        const fetchLeaderboard = async () => {
            setIsLoading(true);
            setError(null);
            try {
                if (viewMode === 'daily' && dateRange.startDate && dateRange.endDate) {
                    // Fetch data for date range
                    await fetchDateRangeLeaderboard(dateRange.startDate, dateRange.endDate);
                } else {
                    const data = await getLeaderboard({
                        minPlays: showAllUsers ? 0 : 1,
                        excludeZeroPnl: !showAllUsers
                    });
                    setLeaderboard(data);
                }
            } catch (error) {
                console.error("Error fetching leaderboard data:", error);
                setError(t('leaderboard.error', 'Failed to load leaderboard data. Please try again later.'));
            } finally {
                setIsLoading(false);
            }
        };

        fetchLeaderboard();
    }, [t, showAllUsers, dateRange, viewMode]);

    const toggleShowAllUsers = () => {
        setShowAllUsers(!showAllUsers);
    };
    
    // Toggle calendar visibility
    const toggleCalendar = () => {
        // When opening, sync pending with actual date range
        if (!showCalendar) {
            setPendingDateRange({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate
            });
        }
        setShowCalendar(!showCalendar);
    };
    
    // Go to previous month
    const prevMonth = (e: React.MouseEvent) => {
        e.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    };
    
    // Go to next month
    const nextMonth = (e: React.MouseEvent) => {
        e.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
    };
    
    // Format the current month and year
    const formatMonth = (): string => {
        return currentMonth.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    };
    
    // Updated handler for day click to support range selection
    const handleDayClick = (day: CalendarDay) => {
        // Create date at noon to avoid timezone issues
        const date = new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 12);
        const dateStr = formatDateString(date);
        
        if (selectionInProgress) {
            // Finish selection - set the end date
            let startDate = pendingDateRange.startDate!;
            let endDate = dateStr;
            
            // Make sure start date is before end date
            if (startDate > endDate) {
                [startDate, endDate] = [endDate, startDate];
            }
            
            setPendingDateRange({ startDate, endDate });
            setSelectionInProgress(false);
        } else {
            // Start new selection - for single day, set both start and end to the same day
            setPendingDateRange({ startDate: dateStr, endDate: dateStr });
            // If user clicks again, they'll start a range selection
            setSelectionInProgress(true);
        }
    };
    
    // Complete date selection if only start date is set
    const completeRangeSelection = () => {
        if (selectionInProgress && pendingDateRange.startDate) {
            setPendingDateRange({ 
                startDate: pendingDateRange.startDate, 
                endDate: pendingDateRange.startDate 
            });
            setSelectionInProgress(false);
        }
    };
    
    // Apply the pending date range and close calendar
    const closeCalendar = () => {
        // Ensure a complete range
        if (selectionInProgress && pendingDateRange.startDate) {
            // If user was in the middle of a range selection but didn't finish,
            // treat it as a single day selection
            setPendingDateRange({
                startDate: pendingDateRange.startDate,
                endDate: pendingDateRange.startDate
            });
            setSelectionInProgress(false);
        }
        
        // Only update the actual dateRange when Apply is clicked
        // This is what triggers the data fetch
        setDateRange(pendingDateRange);
        setViewMode('daily');
        setShowCalendar(false);
    };
    
    // Switch back to all-time view
    const handleAllTimeClick = () => {
        setViewMode('all');
        setPendingDateRange({ startDate: null, endDate: null });
        setDateRange({ startDate: null, endDate: null });
        setSelectionInProgress(false);
        setShowCalendar(false); // Hide calendar
    };

    if (isLoading) {
        return (
            <div className="w-full h-full px-4 py-14 flex justify-center items-center">
                <div className="w-12 h-12 border-t-2 border-pink border-opacity-50 rounded-full animate-spin"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="w-full h-full px-4 py-14 flex justify-center items-center">
                <div className="bg-red-500 bg-opacity-20 border border-red-500 rounded-lg p-4 text-center">
                    <p className="text-red-400">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-full px-4 py-8">
            <div className="mb-8">
                <h1 className="text-white font-['Audiowide'] text-[26px] font-normal leading-normal mb-4">Leaderboard</h1>
                
                {/* Date selector dropdown */}
                <div className="ml-3 relative w-fit" ref={calendarRef}>
                    <button 
                        onClick={toggleCalendar}
                        className="flex items-center"
                    >
                        <span className={`mr-2 text-white font-['Audiowide'] text-[14px] font-normal leading-normal ${selectionInProgress ? 'text-pink animate-pulse' : ''}`}>
                            {selectionInProgress 
                                ? `${getDateDisplay()} (selecting range...)`
                                : getDateDisplay()}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={showCalendar ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"} />
                        </svg>
                    </button>
                    
                    {/* Calendar Dropdown */}
                    {showCalendar && (
                        <div className="absolute left-0 mt-2 z-10 w-[250px] bg-purple-dark rounded-lg shadow-lg overflow-hidden">
                            {/* Calendar Header */}
                            <div className="flex items-center justify-between p-2 border-b border-gray-700">
                                <button onClick={prevMonth} className="text-lg px-2 hover:text-pink">
                                    &lt;
                                </button>
                                <span className="text-sm font-bold">
                                    {formatMonth()}
                                </span>
                                <button onClick={nextMonth} className="text-lg px-2 hover:text-pink">
                                    &gt;
                                </button>
                            </div>
                            
                            {/* Selection Instructions */}
                            <div className="p-2 text-xs text-center text-gray-300 border-b border-gray-700">
                                {selectionInProgress 
                                    ? "Click another day to create a range"
                                    : "Click a day to select it or start a range"}
                            </div>
                            
                            {/* Calendar Grid */}
                            <div className="p-2">
                                <div className="grid grid-cols-7 mb-1">
                                    {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => (
                                        <div key={index} className="text-center text-gray-400 text-xs">
                                            {day}
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-7 gap-1">
                                    {calendarDays.map((day, index) => {
                                        // Determine if this day is in the selected range
                                        const dayStr = formatDateString(day.date);
                                        const isInRange = pendingDateRange.startDate && pendingDateRange.endDate 
                                            ? isDateInRange(dayStr, pendingDateRange.startDate, pendingDateRange.endDate)
                                            : false;
                                        const isRangeStart = dayStr === pendingDateRange.startDate;
                                        const isRangeEnd = dayStr === pendingDateRange.endDate;
                                        
                                        return (
                                            <div key={index} className="relative">
                                                <button
                                                    onClick={() => handleDayClick(day)}
                                                    className={`
                                                        flex items-center justify-center text-xs h-6 w-6 rounded-full
                                                        ${day.isCurrentMonth ? 'font-bold' : 'text-gray-600'}
                                                        ${day.isToday ? 'border border-pink' : ''}
                                                        ${isRangeStart || isRangeEnd ? 'bg-pink text-white' : ''}
                                                        ${isInRange && !isRangeStart && !isRangeEnd ? 'bg-pink bg-opacity-40' : ''}
                                                        hover:bg-purple-light
                                                    `}
                                                >
                                                    {day.dayOfMonth}
                                                </button>
                                                {/* Small pink dot for days with data */}
                                                { false && day.hasData && (
                                                    <div className={`
                                                        absolute bottom-0 left-1/2 transform -translate-x-1/2 
                                                        w-1.5 h-1.5 rounded-full
                                                        ${isInRange || isRangeStart || isRangeEnd ? 'bg-white' : 'bg-pink'}
                                                    `}></div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                
                                {/* Action buttons */}
                                <div className="flex justify-between mt-2 pt-2 border-t border-gray-700">
                                    <button 
                                        onClick={handleAllTimeClick}
                                        className="text-xs px-3 py-1 rounded hover:bg-purple-light"
                                    >
                                        All Time
                                    </button>
                                    <button 
                                        onClick={closeCalendar}
                                        className="text-xs bg-pink px-3 py-1 rounded text-white"
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className="bg-[#2D1651] rounded-lg shadow-lg p-6">
                <h2 className="text-xl font-bold mb-6">Top degens</h2>
                
                <div className="grid grid-cols-3 mb-4 text-sm text-gray-300">
                    <div>Degen</div>
                    <div className="text-right">Net Gains</div>
                    <div className="text-right">Last Flip</div>
                </div>
                
                {leaderboard.length === 0 ? (
                    <div className="text-center py-8">
                        <p className="text-gray-400">
                            {viewMode === 'daily' 
                                ? t('leaderboard.noDataForDay', 'No leaderboard data available for this time period.')
                                : t('leaderboard.noData', 'No leaderboard data available yet. Start playing to get on the board!')}
                        </p>
                    </div>
                ) : (
                    <div className="space-y-1">
                        {leaderboard.map((user: any, index: number) => (
                            <div 
                                key={user._id}
                                className={`grid grid-cols-3 py-3 rounded-lg ${index % 2 === 1 ? "bg-[#392964]" : ""}`}
                            >
                                <div className="flex items-center">
                                    <div className="h-6 w-6 bg-[#8CAAEE] rounded-full mr-2"></div>
                                    <span>{user.username}</span>
                                </div>
                                <div className={`text-right ${
                                    user.pnl > 0 
                                        ? 'text-green-500' 
                                        : user.pnl < 0 
                                            ? 'text-red-500' 
                                            : ''
                                }`}>
                                    <span className="inline-block bg-[#181825] rounded-lg px-2 py-1">
                                        {user.pnl > 0 ? '+' : ''}{formatNumber(user.pnl)}
                                    </span>
                                </div>
                                <div className="text-right text-gray-300">
                                    7 min ago
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}


