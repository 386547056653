import { useWallet } from "@solana/wallet-adapter-react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useUser } from "../../context/UserContext";

export default function ReferralModal() {
    const { t } = useTranslation();
    const wallet = useWallet();
    const { user } = useUser();
    const [copied, setCopied] = useState(false);

    // Use the generated referral code from the user record, or fallback to a derived one.
    const referralCode = 
        user?._id || user?.referralCode || (wallet?.publicKey ? wallet.publicKey.toBase58().slice(0, 8) : "");
    
    // Use the current site for the base URL rather than hardcoding
    const baseUrl = window.location.origin;
    const referralLink = `${baseUrl}?code=${referralCode}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 3000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <dialog id="referral-modal" className="modal">
            <div className="modal-box rps-modal-box">
                <h2 className="text-2xl font-bold mb-4">
                    <strong>{t('referral.title')}</strong>
                </h2>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('referral.howItWorks.title')}</strong>
                </h3>
                <p className="mb-4">
                    {t('referral.howItWorks.description')}
                </p>

                {wallet.connected && (
                    <>
                        <h3 className="text-lg font-semibold mb-2">
                            <strong>{t('referral.yourLink')}</strong>
                        </h3>
                        <div className="flex items-center gap-2 mb-4">
                            <input
                                type="text"
                                value={referralLink}
                                readOnly
                                className="flex-1 bg-transparent border border-gray-600 text-white px-4 py-2 rounded-lg focus:outline-none font-mono text-sm"
                            />
                            <button
                                onClick={copyToClipboard}
                                className="bg-purple-light hover:bg-pink text-white px-4 py-2 rounded-lg transition-colors"
                            >
                                {copied ? t('referral.copied') : t('referral.copy')}
                            </button>
                        </div>
                    </>
                )}

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('referral.policy.title')}</strong>
                </h3>
                <p className="mb-4">
                    {t('referral.policy.description')}
                </p>

                <p className="mb-4">
                    {t('referral.policy.sharing')}
                </p>

                <p className="mb-6">
                    {t('referral.policy.noLimit')}
                </p>

                <div className="modal-action flex justify-end gap-2">
                    <form method="dialog" className="w-full">
                        <button className="w-full py-2 bg-pink text-white font-semibold rounded-md">
                            {t('common.gotIt')}
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}
