import * as anchor from "@coral-xyz/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/UserContext';
import { useStatsContext } from '../context/StatsContext';
import { formatNumber } from '../helpers';

const SubHeader: React.FC = () => {
    const { t } = useTranslation();
    const [balance, setBalance] = useState<number | null>(null);
    const { connection } = useConnection();
    const wallet = useWallet();
    const { user } = useUser();
    
    // Use the stats context instead of directly fetching lucky numbers
    const { stats } = useStatsContext();
    const { luckyNumbers } = stats;

    // Fetch SOL balance
    useEffect(() => {
        const fetchBalance = async () => {
            if (wallet.publicKey) {
                try {
                    const lamports = await connection.getBalance(wallet.publicKey);
                    setBalance(lamports / anchor.web3.LAMPORTS_PER_SOL);
                } catch (error) {
                    console.error("Error fetching SOL balance:", error);
                }
            }
        };
        fetchBalance();
    }, [wallet, connection]);

    return (
        <div className="subheader-bg text-white px-4 py-2 flex flex-col lg:flex-row items-center lg:h-10 gap-2 lg:gap-0">
            {/* Left: Balance and Points */}
            <div className="flex items-center gap-4 text-sm w-full lg:w-[365px]">
                <div className="flex items-center gap-1">
                    <span className="opacity-90">{t('subheader.balance')}</span>
                    <div className="flex items-center gap-1">
                        <img
                            src="/img/sol_logo.png"
                            alt={t('subheader.solLogoAlt')}
                            className="w-4 h-4 object-contain"
                        />
                        <span>{balance !== null ? formatNumber(balance) : t('common.loading')}</span>
                    </div>
                </div>
                <div className="flex items-center gap-1">
                    <span className="opacity-90">{t('subheader.points')}:</span>
                    <span>{formatNumber(user?.points || 0, 0)}</span>
                </div>
            </div>

            {/* Middle: R/P/S progress bar */}
            <div className="flex w-full lg:flex-1 h-6 rounded-full overflow-hidden text-xs">
                <div
                    className="bg-rock flex items-center justify-center text-white whitespace-nowrap"
                    style={{ width: `${luckyNumbers.rock}%` }}
                >
                    {luckyNumbers.rock > 0
                        ? t('subheader.stats.rock', { percentage: luckyNumbers.rock.toFixed(0) })
                        : ""}
                </div>
                <div
                    className="bg-paper flex items-center justify-center text-white whitespace-nowrap"
                    style={{ width: `${luckyNumbers.paper}%` }}
                >
                    {luckyNumbers.paper > 0
                        ? t('subheader.stats.paper', { percentage: luckyNumbers.paper.toFixed(0) })
                        : ""}
                </div>
                <div
                    className="bg-scissors flex items-center justify-center text-white whitespace-nowrap"
                    style={{ width: `${luckyNumbers.scissors}%` }}
                >
                    {luckyNumbers.scissors > 0
                        ? t('subheader.stats.scissors', { percentage: luckyNumbers.scissors.toFixed(0) })
                        : ""}
                </div>
            </div>
        </div>
    );
};

export default SubHeader;
