import { useWallet } from '@solana/wallet-adapter-react';
import React, { useRef, useState, useEffect } from 'react';
import { useAvatarUrl } from '../hooks/useAvatarUrl';
import { uploadFile } from '../services/fileService';

interface AvatarUploadProps {
    currentAvatarUrl?: string | null;
    onAvatarUpdate?: (newUrl: string) => void;
}

export default function AvatarUpload({ currentAvatarUrl, onAvatarUpdate }: AvatarUploadProps) {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const [localAvatarUrl, setLocalAvatarUrl] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const objectUrlRef = useRef<string | null>(null);
    const wallet = useWallet();
    const { avatarUrl, isLoading } = useAvatarUrl(currentAvatarUrl);

    // Update local URL if the prop changes
    useEffect(() => {
        if (avatarUrl) {
            setLocalAvatarUrl(null); // Reset local URL when server URL changes
        }
    }, [avatarUrl]);

    // Cleanup function for object URLs
    const cleanupObjectUrl = () => {
        if (objectUrlRef.current) {
            URL.revokeObjectURL(objectUrlRef.current);
            objectUrlRef.current = null;
        }
    };

    // Clean up object URL when component unmounts
    useEffect(() => {
        return cleanupObjectUrl;
    }, []);

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // Validate file type
        if (!file.type.startsWith('image/')) {
            setError('Please select an image file');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        // Validate file size (e.g., 5MB limit)
        if (file.size > 5 * 1024 * 1024) {
            setError('File size must be less than 5MB');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        // Cleanup any previous object URL
        cleanupObjectUrl();

        setIsUploading(true);
        setUploadProgress(0);
        setError(null);

        // Create a temporary object URL for immediate preview
        const objectUrl = URL.createObjectURL(file);
        objectUrlRef.current = objectUrl;
        setLocalAvatarUrl(objectUrl);

        try {
            // Upload the file using our service - this now returns the object path, not the URL
            const objectPath = await uploadFile(file, (progress: number) => {
                console.log(`Upload progress: ${progress}%`);
                setUploadProgress(progress);
            });
            
            console.log('File uploaded successfully. Object path:', objectPath);
            
            // Call the callback with the new object path only if it's valid
            if (onAvatarUpdate && objectPath) {
                onAvatarUpdate(objectPath);
            } else {
                console.error('Failed to get a valid object path from the server');
                setError('Failed to get a valid object path from the server');
            }
        } catch (err) {
            console.error('Error uploading avatar:', err);
            setError('Failed to upload avatar');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            // Keep the local preview even on error, so we don't flash back to the old image
        } finally {
            setIsUploading(false);
            setUploadProgress(0); // Reset progress
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    // Determine which URL to display
    // Priority: 1. Local preview (if available), 2. Server-provided signed URL, 3. Placeholder
    const displayUrl = isLoading 
        ? "/img/user_placeholder.png" 
        : localAvatarUrl && !isUploading 
            ? localAvatarUrl 
            : avatarUrl || "/img/user_placeholder.png";

    return (
        <div className="relative w-24 h-24 group cursor-pointer" onClick={handleClick}>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileSelect}
                disabled={isUploading}
            />
            <img
                src={isUploading ? "/img/user_placeholder.png" : displayUrl}
                alt="Avatar"
                className="w-full h-full rounded-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity flex flex-col items-center justify-center">
                <span className="text-white text-sm text-center">
                    {isUploading ? 'Uploading...' : 'Change Avatar'}
                </span>
            </div>
            
            {/* Progress bar overlay */}
            {isUploading && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-60 rounded-full">
                    <div className="w-16 h-2 bg-gray-700 rounded-full overflow-hidden">
                        <div 
                            className="h-full bg-green-500 transition-all duration-200"
                            style={{ width: `${uploadProgress}%` }}
                        ></div>
                    </div>
                    <span className="text-white text-xs mt-1">{uploadProgress}%</span>
                </div>
            )}
            
            {error && (
                <div className="absolute top-full mt-2 text-red-500 text-sm">
                    {error}
                </div>
            )}
        </div>
    );
} 