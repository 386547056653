import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getGames, Game, GameWithResult } from '../services/gameService';

interface GameContextType {
  games: (Game | GameWithResult)[];
  isLoading: boolean;
  error: string | null;
  refreshGames: () => Promise<void>;
}

const GameContext = createContext<GameContextType>({
  games: [],
  isLoading: false,
  error: null,
  refreshGames: async () => {}
});

export const useGameContext = () => useContext(GameContext);

export const GameProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [games, setGames] = useState<(Game | GameWithResult)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchGames = useCallback(async (limit = 24) => {
    setIsLoading(true);
    try {
      const recentGames = await getGames(limit, true);
      setGames(recentGames);
      setError(null);
    } catch (error) {
      console.error("Error fetching games:", error);
      setError("Failed to fetch games");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Function to manually refresh games
  const refreshGames = useCallback(async () => {
    await fetchGames();
  }, [fetchGames]);

  useEffect(() => {
    fetchGames();
    
    // Set up interval to refresh games every 5 seconds
    const interval = setInterval(() => {
      fetchGames();
    }, 5000);
    
    return () => clearInterval(interval);
  }, [fetchGames]);

  return (
    <GameContext.Provider value={{ games, isLoading, error, refreshGames }}>
      {children}
    </GameContext.Provider>
  );
}; 