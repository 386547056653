/**
 * Shared utility functions for both client and server
 */

export const formatDate = (date: Date): string => {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export const generateId = (): string => {
  return Math.random().toString(36).substring(2, 9);
};

/**
 * API base URL configuration
 * - In production: Use absolute path from current origin
 * - In development: Use the server URL 'http://localhost:4000'
 * 
 * Note: The /api prefix will be added by the service functions
 */
export const API_BASE_URL = typeof window !== 'undefined' 
  ? (window.location.hostname === 'localhost'
    ? 'http://localhost:4000'
    : window.location.origin)
  : ''; 