import { useTranslation } from 'react-i18next';

export default function HowToPlayModal() {
    const { t } = useTranslation();
    
    return (
        <dialog id="howtoplay-modal" className="modal">
            <div className="modal-box rps-modal-box">
                <h2 className="text-2xl font-bold mb-4">{t('howToPlay.title')}</h2>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('howToPlay.step1.title')}</strong>
                </h3>
                <p className="mb-4">
                    {t('howToPlay.step1.description')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('howToPlay.step2.title')}</strong>
                </h3>
                <p className="mb-4">
                    {t('howToPlay.step2.description')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('howToPlay.step3.title')}</strong>
                </h3>
                <p className="mb-4">
                    {t('howToPlay.step3.description')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('howToPlay.step4.title')}</strong>
                </h3>
                <p className="mb-4">
                    {t('howToPlay.step4.description')}
                </p>

                <h3 className="text-lg font-semibold mb-2">
                    <strong>{t('howToPlay.step5.title')}</strong>
                </h3>
                <p className="mb-2">
                    <strong>{t('howToPlay.step5.win')}</strong>
                </p>
                <p className="mb-2">
                    <strong>{t('howToPlay.step5.lose')}</strong>
                </p>
                <p className="mb-6">
                    <strong>{t('howToPlay.step5.tie')}</strong>
                </p>

                <div className="modal-action flex justify-end gap-2">
                    <form method="dialog" className="w-full">
                        <button className="w-full py-2 bg-pink text-white font-semibold rounded-md">
                            {t('common.gotIt')}
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}
