import React from "react";
import { StatCard } from "./StatCard";
import { ChartSection } from "./ChartSection";

export interface StatsProps {
  header: string;
  statCards: { title: string; value: number }[];
  chartTitle: string;
  legendItems: { color: string; label: string }[];
  values?: number[]; // Array of values corresponding to each legend item
  className?: string;
  forceShowGraph?: boolean;
}

export const Stats: React.FC<StatsProps> = ({
                                              header,
                                              statCards,
                                              chartTitle,
                                              legendItems,
                                              values,
                                              className = '',
                                              forceShowGraph = false,
                                            }) => {
  return (
      <main className={`flex flex-col items-center w-full max-md:px-5 max-md:max-w-full ${className}`}>
        <div className="flex flex-col w-full ">
          <h1 className="text-2xl text-white">{header}</h1>
          <section className="mt-9 max-md:mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-3">
              {statCards.map((stat, index) => (
                  <div
                      key={index}
                      className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full"
                  >
                    <StatCard {...stat} />
                  </div>
              ))}
            </div>
          </section>
        <ChartSection title={chartTitle} legendItems={legendItems} values={values} className={forceShowGraph ? "inline-block" : "hidden md:inline-block"} />
        </div>
      </main>
  );
};
