import React, { useState, useEffect } from "react";
import { Stats } from "../stats/Stats";
import { useTranslation } from 'react-i18next';
import { getGlobalStats, GlobalStats as IGlobalStats } from "../services/globalStatsService";

export default function GlobalStats() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState<{
        overallPlays: number;
        overallVolume: number;
        totalReferralsPaid: number;
        win: number;
        draw: number;
        lose: number;
    }>({
        overallPlays: 0,
        overallVolume: 0,
        totalReferralsPaid: 0,
        win: 0,
        draw: 0,
        lose: 0,
    });

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoading(true);
                const globalStats = await getGlobalStats();
                
                setStats({
                    overallPlays: globalStats.totalPlays || 0,
                    overallVolume: globalStats.totalVolume || 0,
                    totalReferralsPaid: globalStats.totalReferralPaid || 0,
                    win: globalStats.winCount || 0,
                    draw: globalStats.drawCount || 0,
                    lose: globalStats.loseCount || 0,
                });
            } catch (error) {
                console.error("Error fetching global stats:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    const globalStatCards = [
        { title: t('stats.overallPlays'), value: stats.overallPlays },
        { title: t('stats.overallSOLVolume'), value: stats.overallVolume },
        { title: t('stats.totalReferralsPaid'), value: stats.totalReferralsPaid },
    ];

    const legendItems = [
        { color: "bg-emerald-400", label: t('stats.win') },
        { color: "bg-blue-500", label: t('stats.draw') },
        { color: "bg-violet-500", label: t('stats.lose') },
    ];

    const chartValues = [
        stats.win,
        stats.draw,
        stats.lose,
    ];

    if (loading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <p className="text-white">{t('common.loading')}</p>
            </div>
        );
    }

    return (
        <div className="w-full px-4 py-14">
            <Stats
                header={t('stats.globalStats')}
                statCards={globalStatCards}
                chartTitle={t('stats.overallStats')}
                legendItems={legendItems}
                values={chartValues}
                forceShowGraph={true}
            />
        </div>
    );
}
