import React, { useState, useEffect } from 'react';
import { 
  getUsers, 
  toggleUserStatus as toggleUserStatusService,
  getCommissionRate as getCommissionRateService,
  updateCommissionRate as updateCommissionRateService,
  getSiteSetting,
  updateSiteSetting,
  DEFAULT_SETTINGS,
  AdminUserDocument,
  PaginatedUsers,
  SiteSettings,
  logout
} from '../services/adminService';

interface AdminPageProps {}

const AdminPage: React.FC<AdminPageProps> = () => {
    // State for site settings form
    const [settings, setSettings] = useState<SiteSettings>({
        min_bet: 0.01,
        max_bet: 5,
        max_volume_hourly: 100,
        max_volume_daily: 1000,
    });
    
    // Separate state for commission rate
    const [commissionRate, setCommissionRate] = useState<number>(1);
    const [loadingCommission, setLoadingCommission] = useState<boolean>(false);

    // Users state
    const [users, setUsers] = useState<AdminUserDocument[]>([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    // Loading state
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);
    
    // Pagination and search state
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const USERS_PER_PAGE = 10; // Configurable page size
    
    // Load users on page load, search change, or page change
    useEffect(() => {
        const fetchUsers = async () => {
            setIsLoading(true);
            try {
                const data: PaginatedUsers = await getUsers(currentPage, USERS_PER_PAGE, searchQuery);
                setUsers(data.users);
                setTotalUsers(data.totalUsers);
            } catch (error) {
                console.error("Error fetching users:", error);
                showToast('Failed to load users', 'error');
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchUsers();
    }, [currentPage, searchQuery, USERS_PER_PAGE]);
    
    // Load settings and commission rate
    useEffect(() => {
        const loadSettings = async () => {
            try {
                // Load settings from adminService
                const minBet = await getSiteSetting('min_bet');
                const maxBet = await getSiteSetting('max_bet');
                const maxVolumeHourly = await getSiteSetting('max_volume_hourly');
                const maxVolumeDaily = await getSiteSetting('max_volume_daily');
                
                setSettings({
                    min_bet: minBet,
                    max_bet: maxBet,
                    max_volume_hourly: maxVolumeHourly,
                    max_volume_daily: maxVolumeDaily
                });
                
                // Load commission rate
                setLoadingCommission(true);
                try {
                    const rate = await getCommissionRateService();
                    setCommissionRate(rate);
                } catch (error) {
                    console.error('Error loading commission rate:', error);
                    showToast('Failed to load commission rate', 'error');
                } finally {
                    setLoadingCommission(false);
                }
            } catch (error) {
                console.error('Error loading settings:', error);
                showToast('Failed to load settings', 'error');
            }
        };
        
        loadSettings();
    }, []);

    // Handle search input changes
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };
    
    // Handle form input changes
    const handleSettingChange = (key: keyof SiteSettings, value: string) => {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
            setSettings({ ...settings, [key]: numValue });
        }
    };
    
    // Handle commission rate change
    const handleCommissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Only allow integers between 0 and 100
        if (value === '' || /^\d+$/.test(value)) {
            const numValue = parseInt(value);
            if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                setCommissionRate(numValue);
            } else if (value === '') {
                setCommissionRate(0);
            }
        }
    };

    // Function to show toast
    const showToast = (message: string, type: 'success' | 'error' | 'info') => {
        setToast({ message, type });
        // Auto-hide after 3 seconds
        setTimeout(() => setToast(null), 3000);
    };

    // Save settings
    const saveSettings = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            // Update settings using the adminService
            const minBetSuccess = await updateSiteSetting('min_bet', settings.min_bet);
            const maxBetSuccess = await updateSiteSetting('max_bet', settings.max_bet);
            const maxVolumeHourlySuccess = await updateSiteSetting('max_volume_hourly', settings.max_volume_hourly);
            const maxVolumeDailySuccess = await updateSiteSetting('max_volume_daily', settings.max_volume_daily);
            
            const allSettingsUpdated = minBetSuccess && maxBetSuccess && maxVolumeHourlySuccess && maxVolumeDailySuccess;
            
            // Update commission rate
            try {
                const commissionSuccess = await updateCommissionRateService(commissionRate);
                if (allSettingsUpdated && commissionSuccess) {
                    showToast('Settings and commission rate updated successfully', 'success');
                } else if (allSettingsUpdated) {
                    showToast('Settings saved but failed to update commission rate', 'error');
                } else if (commissionSuccess) {
                    showToast('Commission rate updated but some settings failed to save', 'error');
                } else {
                    showToast('Failed to update settings and commission rate', 'error');
                }
            } catch (error) {
                console.error('Error updating commission rate:', error);
                showToast('Settings saved but failed to update commission rate', 'error');
            }
        } catch (error) {
            console.error('Error saving settings:', error);
            showToast('Error saving settings', 'error');
        } finally {
            setLoading(false);
        }
    };
    
    // Toggle user enabled status
    const toggleUserStatus = async (userId: string, currentStatus?: boolean) => {
        setLoading(true);
        
        try {
            const success = await toggleUserStatusService(userId, !currentStatus);
            if (success) {
                // Update the user in the local state
                setUsers(prevUsers => prevUsers.map(user => 
                    user._id === userId 
                        ? { ...user, enabled: !currentStatus } 
                        : user
                ));
                showToast('User status updated', 'success');
            } else {
                showToast('Failed to update user status', 'error');
            }
        } catch (error) {
            console.error('Error toggling user status:', error);
            showToast(`Error changing user status: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
        } finally {
            setLoading(false);
        }
    };

    // Handle logout
    const handleLogout = () => {
        logout();
    };

    // Calculate pagination
    const totalPages = Math.ceil(totalUsers / USERS_PER_PAGE);
    
    // Tabs state
    const [activeTab, setActiveTab] = useState<'settings' | 'users'>('settings');

    return (
        <div className="w-full max-w-6xl mx-auto p-6 text-white min-h-screen mt-10">
            {/* Toast Container */}
            <div className="toast toast-top toast-end">
                {toast && (
                    <div className={`alert ${toast.type === 'success' ? 'alert-success' : toast.type === 'error' ? 'alert-error' : 'alert-info'}`}>
                        <span>{toast.message}</span>
                    </div>
                )}
            </div>

            <div className="flex justify-between items-center mb-8">
                <h1 className="text-4xl font-bold">Admin Dashboard</h1>
                <button
                    onClick={handleLogout}
                    className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md font-semibold"
                >
                    Logout
                </button>
            </div>
            
            {/* Tabs */}
            <div className="tabs tabs-boxed mb-6">
                <a className={`tab tab-lg flex-1 ${activeTab === 'settings' ? 'tab-active' : ''}`} onClick={() => setActiveTab('settings')}>
                    Site Settings
                </a>
                <a className={`tab tab-lg flex-1 ${activeTab === 'users' ? 'tab-active' : ''}`} onClick={() => setActiveTab('users')}>
                    User Management
                </a>
            </div>

            {/* Site Settings Tab */}
            {activeTab === 'settings' && (
                <div className="betform-bg p-6 rounded-xl mb-8">
                    <h2 className="text-2xl font-bold mb-4">Site Settings</h2>
                    
                    <form onSubmit={saveSettings}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                            {Object.entries(settings).map(([key, value]) => (
                                <div key={key} className="flex flex-col">
                                    <label className="mb-2 capitalize">
                                        {key.replace(/_/g, ' ')}
                                    </label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={value}
                                        onChange={(e) => handleSettingChange(key as keyof SiteSettings, e.target.value)}
                                        className="px-3 py-2 rounded-md bg-[#FFFFFF1A] text-white focus:outline-none"
                                    />
                                </div>
                            ))}
                            
                            {/* Commission Rate Input */}
                            <div className="flex flex-col">
                                <label className="mb-2 capitalize">
                                    House Commission (%)
                                </label>
                                <input
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="100"
                                    value={loadingCommission ? '' : commissionRate}
                                    onChange={handleCommissionChange}
                                    disabled={loadingCommission}
                                    className="px-3 py-2 rounded-md bg-[#FFFFFF1A] text-white focus:outline-none disabled:opacity-50"
                                />
                                {loadingCommission && (
                                    <span className="text-sm text-gray-400 mt-1">Loading from program...</span>
                                )}
                            </div>
                        </div>
                        
                        <button
                            type="submit"
                            disabled={loading}
                            className="px-6 py-3 bg-[#9509B7] hover:bg-[#7A0498] rounded-md font-semibold"
                        >
                            {loading ? 'Saving...' : 'Save Settings'}
                        </button>
                    </form>
                </div>
            )}

            {/* User Management Tab */}
            {activeTab === 'users' && (
                <div className="betform-bg p-6 rounded-xl">
                    <h2 className="text-2xl font-bold mb-4">User Management</h2>
                    
                    {/* Search Input */}
                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="Search by username or wallet address..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-full px-4 py-2 rounded-md bg-[#FFFFFF1A] text-white focus:outline-none"
                        />
                    </div>
                    
                    {isLoading ? (
                        <div className="flex justify-center items-center p-8">
                            <div className="w-12 h-12 border-t-2 border-pink border-opacity-50 rounded-full animate-spin"></div>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-x-auto">
                                <table className="w-full border-collapse">
                                    <thead>
                                        <tr className="border-b border-purple-800">
                                            <th className="py-2 px-4 text-left">Username</th>
                                            <th className="py-2 px-4 text-left">Wallet Address</th>
                                            <th className="py-2 px-4 text-left">Total Plays</th>
                                            <th className="py-2 px-4 text-left">Total Volume</th>
                                            <th className="py-2 px-4 text-left">Status</th>
                                            <th className="py-2 px-4 text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user: AdminUserDocument) => (
                                            <tr key={user._id} className="border-b border-purple-800">
                                                <td className="py-3 px-4">{user.username}</td>
                                                <td className="py-3 px-4">
                                                    <div className="flex items-center gap-2">
                                                        <span className="font-mono text-sm">
                                                            {user.walletAddress ? `${user.walletAddress.slice(0, 6)}...${user.walletAddress.slice(-4)}` : 'N/A'}
                                                        </span>
                                                        {user.walletAddress && (
                                                            <button
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(user.walletAddress);
                                                                    showToast('Wallet address copied to clipboard', 'success');
                                                                }}
                                                                className="p-1 hover:bg-[#FFFFFF1A] rounded"
                                                                title="Copy to clipboard"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v11a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                                                                </svg>
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="py-3 px-4">{user.totalPlays || 0}</td>
                                                <td className="py-3 px-4">{user.totalVolume?.toFixed(2) || '0.00'}</td>
                                                <td className="py-3 px-4">
                                                    <span className={`inline-block px-2 py-1 rounded ${user.enabled !== false ? 'bg-green-600' : 'bg-red-600'}`}>
                                                        {user.enabled !== false ? 'Enabled' : 'Disabled'}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-4 text-right">
                                                    <button
                                                        onClick={() => toggleUserStatus(user._id!, user.enabled)}
                                                        className={`px-3 py-1 rounded ${user.enabled !== false ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'}`}
                                                    >
                                                        {user.enabled !== false ? 'Disable' : 'Enable'}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        
                                        {users.length === 0 && (
                                            <tr>
                                                <td colSpan={6} className="py-4 text-center">No users found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination */}
                            {totalPages > 1 && (
                                <div className="flex justify-center items-center mt-4 gap-2">
                                    <button
                                        onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                                        disabled={currentPage === 1}
                                        className="px-3 py-1 rounded bg-[#9509B7] hover:bg-[#7A0498] disabled:opacity-50"
                                    >
                                        Previous
                                    </button>
                                    <span className="mx-2">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                                        disabled={currentPage === totalPages}
                                        className="px-3 py-1 rounded bg-[#9509B7] hover:bg-[#7A0498] disabled:opacity-50"
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdminPage; 