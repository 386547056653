import React from "react";
import { useTranslation } from 'react-i18next';
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";

const ComingSoon: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-900">
            <h1 className="text-white text-4xl font-bold">{t('common.comingSoon')}</h1>
            <div className="mt-4">
                <WalletMultiButton/>
            </div>
        </div>
    );
};

export default ComingSoon;
