import React, { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useTranslation } from 'react-i18next';
import { Stats } from "../stats/Stats";
import { User, getUserByWalletAddress } from "../services/userService";
import { Game, getUserGames, getUserGameStats, GameStats } from "../services/gameService";
import { formatNumber, truncateString } from "../helpers";

const formatRelativeTime = (date: Date): string => {
    const now = new Date();
    const diffMs = now.getTime() - new Date(date).getTime();
    const diffSec = Math.floor(diffMs / 1000);
    if (diffSec < 60) return `${diffSec}s ago`;
    const diffMin = Math.floor(diffSec / 60);
    if (diffMin < 60) return `${diffMin}m ago`;
    const diffHr = Math.floor(diffMin / 60);
    if (diffHr < 24) return `${diffHr}h ago`;
    const diffDay = Math.floor(diffHr / 24);
    return `${diffDay}d ago`;
};

export default function PersonalStats() {
    const { t } = useTranslation();
    const wallet = useWallet();
    const [personalStats, setPersonalStats] = useState<{
        totalPlays: number;
        totalVolume: number;
        points: number;
        win: number;
        draw: number;
        lose: number;
    } | null>(null);
    const [userGames, setUserGames] = useState<Game[]>([]);
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState<string>("");

    useEffect(() => {
        const fetchUserData = async () => {
            if (!wallet.publicKey) {
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const walletAddress = wallet.publicKey.toBase58();
                
                // Get user information
                const user = await getUserByWalletAddress(walletAddress);
                setUsername(user?.username || truncateString(walletAddress, 8));
                
                // Get game statistics
                const gameStats = await getUserGameStats(walletAddress);
                
                // Get recent games for the user
                const games = await getUserGames(walletAddress, 10);
                setUserGames(games);

                // Set personal stats
                setPersonalStats({
                    totalPlays: user?.totalPlays || 0,
                    totalVolume: user?.totalVolume || 0,
                    points: user?.points || 0,
                    win: gameStats.wins,
                    draw: gameStats.draws,
                    lose: gameStats.losses,
                });
            } catch (error) {
                console.error("Error fetching personal stats:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [wallet.publicKey]);

    const personalStatCards = personalStats
        ? [
            { title: t('stats.yourPlays'), value: personalStats.totalPlays },
            { title: t('stats.yourSOLVolume'), value: personalStats.totalVolume },
            { title: t('stats.yourPoints'), value: personalStats.points },
        ]
        : [];

    const legendItems = [
        { color: "bg-emerald-400", label: t('stats.win') },
        { color: "bg-blue-500", label: t('stats.draw') },
        { color: "bg-violet-500", label: t('stats.lose') },
    ];

    const chartValues = personalStats
        ? [personalStats.win, personalStats.draw, personalStats.lose]
        : [0, 0, 0];

    const totalGames = personalStats ? personalStats.win + personalStats.draw + personalStats.lose : 0;
    const getPercentage = (value: number) => totalGames > 0 ? Math.round((value / totalGames) * 100) : 0;

    if (loading && !wallet.publicKey) {
        return (
            <div className="w-full h-full p-4 py-14 flex items-center justify-center">
                <p className="text-lg">{t('common.connectWalletToView')}</p>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="w-full h-full p-4 py-14 flex items-center justify-center">
                <p className="text-lg">{t('common.loading')}</p>
            </div>
        );
    }

    return (
        <div className="w-full h-full p-4 py-14">
            <Stats
                header={t('stats.yourStats')}
                statCards={personalStatCards}
                chartTitle={t('stats.overallStats')}
                legendItems={legendItems}
                values={chartValues}
                forceShowGraph={false}
            />
            
            {/* Stats percentages for mobile */}
            {personalStats && (
                <div className="md:hidden bg-purple p-4 rounded-lg my-6">
                    <h2 className="text-xl font-bold mb-4">{t('stats.overallStats')}</h2>
                    <div className="grid grid-cols-3 gap-4 text-center">
                        <div>
                            <div className="text-emerald-400 font-bold text-xl">{getPercentage(personalStats.win)}%</div>
                            <div className="text-sm">{t('stats.win')}</div>
                        </div>
                        <div>
                            <div className="text-blue-500 font-bold text-xl">{getPercentage(personalStats.draw)}%</div>
                            <div className="text-sm">{t('stats.draw')}</div>
                        </div>
                        <div>
                            <div className="text-violet-500 font-bold text-xl">{getPercentage(personalStats.lose)}%</div>
                            <div className="text-sm">{t('stats.lose')}</div>
                        </div>
                    </div>
                </div>
            )}

            <div className="bg-purple p-4 rounded-lg mt-6">
                <h2 className="text-xl font-bold mb-4">{t('stats.latestPlays')}</h2>
                <table className="w-full text-left">
                    <thead>
                    <tr>
                        <th className="pb-2 w-full">{t('stats.columns.degen')}</th>
                        <th className="pb-2 text-right whitespace-nowrap">{t('stats.columns.netGains')}</th>
                        <th className="pl-4 pb-2 text-right whitespace-nowrap">{t('stats.columns.lastBet')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userGames.map((game, index) => {
                        const netGains = game.outcome === "win"
                            ? game.payout - game.betAmount
                            : game.outcome === "lose"
                                ? -game.betAmount
                                : 0;
                        return (
                            <tr
                                key={game._id || index}
                                className={`${index % 2 === 1 ? "bg-even-row" : ""}`}
                            >
                                <td className="py-2">
                                    {t('stats.playerResult', {
                                        username: username,
                                        result: t(`stats.result.${game.outcome}`)
                                    })}
                                </td>
                                <td className="p-2 text-right">
                                    <div className="flex items-center justify-end gap-1">
                                        <img
                                            src="/img/sol_logo.png"
                                            alt="SOL"
                                            className="w-4 h-4"
                                        />
                                        <span>{formatNumber(netGains)}</span>
                                    </div>
                                </td>
                                <td className="py-2 text-right whitespace-nowrap">{formatRelativeTime(new Date(game.timestamp))}</td>
                            </tr>
                        );
                    })}
                    {userGames.length === 0 && (
                        <tr>
                            <td colSpan={3} className="py-2 text-center">
                                {t('stats.noGamesPlayed')}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
