import { post, get } from './apiService';
import { User } from './userService';

/**
 * Interface for referral information
 */
export interface Referral {
  _id?: string;
  referrerId: string;  // Referrer's ID or code
  refereeId: string;   // User who was referred ID
  commissionEarned: number;
  createdAt?: Date;
}

/**
 * Interface for referral statistics
 */
export interface ReferralStats {
  totalReferred: number;
  totalCommission: number;
  referrals: Referral[];
}

/**
 * Interface for referral leaderboard data
 */
export interface ReferralData {
  referrerId: string;
  commission: number;
  degen: string;
}

/**
 * Generate a referral link for a user
 * @param user The user to generate a referral link for
 * @returns The referral link or null if the user has no referral code
 */
export const generateReferralLink = (user: User | null): string | null => {
  if (!user || !user.referralCode) return null;
  
  const baseUrl = window.location.origin;
  return `${baseUrl}?ref=${user.referralCode}`;
};

/**
 * Store a referral code in localStorage
 * @param code The referral code to store
 */
export const storeReferralCode = (code: string): void => {
  try {
    localStorage.setItem('referralCode', code);
  } catch (e) {
    console.error('Failed to store referral code:', e);
  }
};

/**
 * Get the stored referral code from localStorage
 * @returns The stored referral code or null if none exists
 */
export const getStoredReferralCode = (): string | null => {
  try {
    return localStorage.getItem('referralCode');
  } catch (e) {
    console.error('Failed to retrieve referral code:', e);
    return null;
  }
};

/**
 * Clear the stored referral code from localStorage
 */
export const clearStoredReferralCode = (): void => {
  try {
    localStorage.removeItem('referralCode');
  } catch (e) {
    console.error('Failed to clear referral code:', e);
  }
};

/**
 * Create a referral
 * @param referrerId The ID of the referrer
 * @param refereeId The ID of the referee
 * @param commissionEarned The commission earned from the referral
 * @returns Promise resolving to the created referral
 */
export const createReferral = async (
  referrerId: string,
  refereeId: string,
  commissionEarned: number
): Promise<Referral> => {
  try {
    const newReferral: Referral = {
      referrerId,
      refereeId,
      commissionEarned,
      createdAt: new Date()
    };
    
    const response = await post('/api/referrals', newReferral);
    return response.data;
  } catch (error) {
    console.error('Error creating referral:', error);
    // Return mock data for now while API is being developed
    return {
      _id: `ref_${Date.now()}`,
      referrerId,
      refereeId,
      commissionEarned,
      createdAt: new Date()
    };
  }
};

/**
 * Get referrals by referrer ID
 * @param referrerId The ID of the referrer
 * @returns Promise resolving to the referrer's stats
 */
export const getReferralsByReferrerId = async (referrerId: string): Promise<ReferralStats> => {
  try {
    const response = await get(`/api/referrals/${referrerId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting referrals:', error);
    // Return mock data for now while API is being developed
    const mockReferrals: Referral[] = [];
    const count = Math.floor(Math.random() * 10);
    
    let totalCommission = 0;
    for (let i = 0; i < count; i++) {
      const commission = parseFloat((Math.random() * 5).toFixed(2));
      totalCommission += commission;
      
      mockReferrals.push({
        _id: `ref_${Date.now()}_${i}`,
        referrerId,
        refereeId: `user_${Date.now()}_${i}`,
        commissionEarned: commission,
        createdAt: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000)
      });
    }
    
    return {
      totalReferred: count,
      totalCommission,
      referrals: mockReferrals
    };
  }
};

/**
 * Get the referral leaderboard
 * @returns Promise resolving to the referral leaderboard
 */
export const getReferralLeaderboard = async (): Promise<ReferralData[]> => {
  try {
    const response = await get('/api/referrals/leaderboard');
    return response.data;
  } catch (error) {
    console.error('Error getting referral leaderboard:', error);
    // Return mock data for now while API is being developed
    const mockLeaderboard: ReferralData[] = [];
    const degens = ['Crypto King', 'Diamond Hands', 'HODL Master', 'Moon Boy', 'Whale', 'Paper Hands', 'Degen Trader', 'NFT Collector', 'Meme Coin Lover', 'Blockchain Believer'];
    
    for (let i = 0; i < 10; i++) {
      mockLeaderboard.push({
        referrerId: `user_${i}`,
        commission: parseFloat((Math.random() * 100).toFixed(2)),
        degen: degens[i]
      });
    }
    
    // Sort by commission in descending order
    return mockLeaderboard.sort((a, b) => b.commission - a.commission);
  }
};

/**
 * Get referrer balance from blockchain
 * @param referrerId The ID of the referrer
 * @returns Promise resolving to the referrer's balance
 */
export const getReferrerBalance = async (referrerId: string): Promise<number> => {
  try {
    const response = await get(`/api/referrals/${referrerId}/balance`);
    return response.data.balance;
  } catch (error) {
    console.error('Error getting referrer balance:', error);
    // Return mock data for now while API is being developed
    return parseFloat((Math.random() * 50).toFixed(2));
  }
};

/**
 * Claim referrer rewards
 * @param referrerId The ID of the referrer
 * @param amount The amount to claim
 * @returns Promise resolving to a boolean indicating success
 */
export const claimReferrerRewards = async (referrerId: string, amount: number): Promise<boolean> => {
  try {
    const response = await post(`/api/referrals/${referrerId}/claim`, { amount });
    return response.data.success;
  } catch (error) {
    console.error('Error claiming referrer rewards:', error);
    // Return mock success for now while API is being developed
    return true;
  }
};