import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { isAuthenticated, isAdmin } from '../services/adminService';

interface ProtectedAdminRouteProps {
  children: React.ReactNode;
}

const ProtectedAdminRoute: React.FC<ProtectedAdminRouteProps> = ({ children }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // Check authentication status
    const checkAuth = async () => {
      const authenticated = isAuthenticated();
      const adminStatus = isAdmin();
      
      setAuthorized(authenticated && adminStatus);
      setLoading(false);
    };
    
    checkAuth();
  }, []);

  if (loading) {
    // Show loading state while checking auth
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-purple-500 mb-4" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="text-white">Verifying admin access...</p>
        </div>
      </div>
    );
  }

  if (!authorized) {
    // Redirect to login page with return path
    return <Redirect to={{ 
      pathname: '/login',
      state: { from: location.pathname }
    }} />;
  }

  // Render the protected content
  return <>{children}</>;
};

export default ProtectedAdminRoute; 