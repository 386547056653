import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../helpers';
import { useAvatarByWallet } from '../hooks/useAvatarByWallet';
import Confetti from './Confetti';
import { useUser } from "../context/UserContext";

interface GameResult {
    houseChoice: number;
    result: 'Win' | 'Lose' | 'Tie';
    payout: number;
}

interface Game {
    _id: string;
    gameIdNumber: number;
    username: string;
    userId?: string; // Add userId to store wallet address
    gameResult: GameResult;
    status: string;
    createdAt: Date;
}

// User avatar component using new hook
const UserAvatar: React.FC<{ walletAddress?: string; altText: string }> = ({ walletAddress, altText }) => {
    const { avatarUrl, isLoading } = useAvatarByWallet(walletAddress);
    
    return (
        <img
            src={isLoading ? "/img/user_placeholder.png" : (avatarUrl || "/img/user_placeholder.png")}
            alt={altText}
            className="w-[55.648px] h-[52.625px] rounded-full object-cover"
        />
    );
};

interface GameFlowProps {
    userChoice: number | null;
    houseChoice: number | null;
    gameState: 'betting' | 'countdown' | 'result';
    amount: number;
    game: Game | null;
    isBetFormHidden?: boolean;
    onContinue?: () => void;
    shouldStartProgress?: boolean;
    startTime?: number;
    onCountdownComplete?: () => void;
}

const getMoveName = (choice: number | null): string => {
    if (choice === null) return '';
    return choice === 0 ? 'Rock' : choice === 1 ? 'Paper' : 'Scissors';
};

const GameFlow: React.FC<GameFlowProps> = ({
    userChoice,
    houseChoice,
    gameState,
    amount,
    game,
    isBetFormHidden = false,
    onContinue,
    shouldStartProgress = false,
    startTime,
    onCountdownComplete
}) => {
    const { t, i18n } = useTranslation();
    const [progress, setProgress] = useState(0);
    const [countdown, setCountdown] = useState(3);
    const [, forceUpdate] = useState({});
    const { user } = useUser();

    // Force re-render when language changes
    useEffect(() => {
        const handleLanguageChange = () => {
            forceUpdate({});
        };

        i18n.on('languageChanged', handleLanguageChange);
        
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    // Handle countdown
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (gameState === 'countdown' && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
                if (countdown === 1 && onCountdownComplete) {
                    onCountdownComplete();
                }
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown, gameState, onCountdownComplete]);

    // Handle progress bar animation
    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const PROGRESS_DURATION_MS = 3000;
        const updateProgress = () => {
            if (!startTime) return;
            const elapsed = performance.now() - startTime;

            // First 5 seconds: move to 90%
            if (elapsed < PROGRESS_DURATION_MS) {
                const newProgress = Math.min(90, (elapsed / PROGRESS_DURATION_MS) * 90);
                setProgress(newProgress);
            } else {
                // After 5 seconds: very slow progress towards 95%
                const slowProgress = 90 + (Math.min(elapsed - PROGRESS_DURATION_MS, PROGRESS_DURATION_MS) / PROGRESS_DURATION_MS) * 5;
                setProgress(slowProgress);
            }
        };

        if (gameState === 'countdown' && shouldStartProgress && countdown === 0 && startTime) {
            setProgress(0);
            intervalId = setInterval(updateProgress, 100);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [gameState, shouldStartProgress, countdown, startTime]);

    // Reset progress when game state changes to result
    useEffect(() => {
        if (gameState === 'result') {
            setProgress(100);
        }
    }, [gameState]);

    const getHandImage = (choice: number | null, isHouse: boolean = false) => {
        const side = isHouse ? 'right' : 'left';
        
        if (gameState === 'countdown' || choice === null) {
            return `/gif/rock ${side}.gif`;
        }
        
        if (gameState === 'result') {
            switch (choice) {
                case 0: return `/gif/rock ${side}.gif`;
                case 1: return `/gif/paper ${side}.gif`;
                case 2: return `/gif/scissors ${side}.gif`;
                default: return `/gif/rock ${side}.gif`;
            }
        }
        
        return '/img/open-hand.png';
    };

    const getResultPillStyle = () => {
        if (!game || !game.gameResult) return '';
        
        switch (game.gameResult.result) {
            case 'Win':
                return 'bg-[#19FF14]';
            case 'Lose':
                return 'bg-[#FF1414]';
            case 'Tie':
                return 'bg-[#FFC700]';
            default:
                return '';
        }
    };

    const getResultText = () => {
        if (!game || !game.gameResult) return '';
        
        switch (game.gameResult.result) {
            case 'Win':
                return t('gameFlow.result.won');
            case 'Lose':
                return t('gameFlow.result.lost');
            case 'Tie':
                return t('gameFlow.result.draw');
            default:
                return '';
        }
    };

    const renderResult = () => {
        if (!game || !game.gameResult) return null;
        
        const resultText = game.gameResult.result === 'Win' 
            ? t('gameFlow.result.wonAmount', { amount: formatNumber(game.gameResult.payout) })
            : game.gameResult.result === 'Lose'
            ? t('gameFlow.result.lostGame')
            : t('gameFlow.result.itsATie');

        return (
            <div className="text-center">
                <div className="text-2xl font-bold mb-2">{resultText}</div>
                <div className="text-lg">
                    {t('gameFlow.result.userChoice', { username: game.username, choice: t(`betting.moves.${getMoveName(userChoice).toLowerCase()}`) })}
                </div>
                <div className="text-lg">
                    {t('gameFlow.result.houseChoice', { choice: t(`betting.moves.${getMoveName(game.gameResult.houseChoice).toLowerCase()}`) })}
                </div>
            </div>
        );
    };

    const renderGameContent = () => {
        switch (gameState) {
            case 'countdown':
                return (
                    <div className="flex flex-col items-center justify-center gap-4">
                        {countdown > 0 ? (
                            <div className="w-[100px] h-[100px] md:w-[140px] md:h-[140px] rounded-full border-[3px] border-[#684B97] flex items-center justify-center">
                                <div className="text-white text-[40px] md:text-[60px] font-normal">
                                    {countdown}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="w-[150px] md:w-[300px] h-2 bg-[#462579] rounded-full overflow-hidden">
                                    <div 
                                        className="h-full bg-[#9509B7] transition-all duration-100 ease-linear"
                                        style={{ width: `${progress}%` }}
                                    />
                                </div>
                                <div className="text-white text-center text-base md:text-lg font-semibold">
                                    Confirming on chain...
                                </div>
                            </>
                        )}
                    </div>
                );
            case 'betting':
                return null;
            case 'result':
                return game ? (
                    <div className="relative flex flex-col items-center justify-center gap-3">
                        {game.gameResult.result === 'Win' && <Confetti />}
                        <div className="relative z-10 flex flex-col items-center gap-3 p-4 rounded-lg ">
                            <UserAvatar 
                                walletAddress={user.walletAddress} 
                                altText={t('gameFlow.avatarAlt')} 
                            />
                            <div className="flex flex-col items-center gap-1">
                                <div className="flex items-center gap-2">
                                    <div className="text-white font-['Audiowide'] text-2xl">
                                        {game.username}
                                    </div>
                                    <div className={classnames(
                                        'px-4 py-1 rounded-[7px] font-["Audiowide"] text-black text-base',
                                        getResultPillStyle()
                                    )}>
                                        {getResultText()}
                                    </div>
                                </div>
                                <div className="text-white font-['Audiowide'] text-xl">
                                    {formatNumber(amount)} SOL
                                </div>
                                
                                <button
                                    onClick={onContinue}
                                    className="mt-4 px-8 py-2 bg-[#9509B7] text-white rounded-md font-semibold hover:bg-[#A020F0] transition-colors"
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null;
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col items-center w-full">
            <div className={`relative flex justify-between items-center w-full ${isBetFormHidden ? 'max-h-[600px]' : 'max-h-[200px]'}`}>
                {/* User name label */}
                {gameState === 'result' && game && (
                    <div className="absolute z-30 left-[5%] top-20 text-white font-['Audiowide'] text-[14px] font-normal">
                        {game.username}
                    </div>
                )}
                
                <div className={`w-1/3 flex justify-start`}>
                    <img 
                        src={getHandImage(userChoice)} 
                        alt={t('gameFlow.userHandAlt')}
                        className={`${isBetFormHidden ? 'w-[600px] h-[600px]' : 'w-96 h-96'} object-contain`}
                    />
                </div>

                <div className={`absolute left-1/2 ${isBetFormHidden ? 'top-1/2' : 'top-1/3'} transform -translate-x-1/2 ${isBetFormHidden ? '-translate-y-1/3' : '-translate-y-1/2'} z-10 flex items-center justify-center`}>
                    {renderGameContent()}
                </div>

                {/* House label */}
                {gameState === 'result' && (
                    <div className="absolute z-30 right-[5%] top-20 text-white font-['Audiowide'] text-[14px] font-normal">
                        House
                    </div>
                )}
                
                <div className={`w-1/3 flex justify-end`}>
                    <img 
                        src={getHandImage(houseChoice, true)} 
                        alt={t('gameFlow.houseHandAlt')}
                        className={`${isBetFormHidden ? 'w-[600px] h-[600px]' : 'w-96 h-96'} object-contain`}
                    />
                </div>
            </div>
        </div>
    );
};

export default GameFlow; 