import * as React from "react";
import { LegendItemProps } from "./types";

export const LegendItem: React.FC<LegendItemProps> = ({ color, label, value }) => {
  return (
    <div className="flex items-center gap-2 mb-2">
      <div className={`w-4 h-4 rounded-full ${color}`} />
      <span className="text-white">
        {label}{value !== undefined ? `: ${value}` : ""}
      </span>
    </div>
  );
};
