import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { GameWithResult } from "../services/gameService";
import { useGameContext } from "../context/GameContext";

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const [latestMoves, setLatestMoves] = useState<{ id: string; move: string }[]>([]);
    
    // Use game context to get games data
    const { games } = useGameContext();

    // Process games data when it changes
    useEffect(() => {
        if (games && games.length > 0) {
            // Get all games that have a gameResult property
            const movesWithResult = games
                .filter(game => game && typeof game === 'object' && 'gameResult' in game && game.gameResult)
                .slice(0, 20)
                .map((game) => {
                    // We've filtered for games with gameResult
                    const typedGame = game as unknown as GameWithResult;
                    const choice = typedGame.gameResult?.house_choice;
                    let moveType = 'rock';
                    
                    switch (choice) {
                        case 0: moveType = 'rock'; break;
                        case 1: moveType = 'paper'; break;
                        case 2: moveType = 'scissors'; break;
                    }
                    
                    return {
                        id: game._id,
                        move: moveType
                    };
                });
            
            if (movesWithResult.length > 0) {
                setLatestMoves(movesWithResult);
            }
        }
    }, [games]);

    return (
        <footer className="footer-bg text-white p-4">
            <div className="flex flex-col items-center">
                <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6 mb-3">
                    <div className="flex items-center gap-4">
                        <span style={{
                            color: '#FFF',
                            fontFamily: 'Audiowide',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal'
                        }}>
                            Latest bets
                        </span>
                        <div className="w-[2px] h-6 bg-[#9509B7]" />
                    </div>
                    <div className="flex items-center overflow-x-auto">
                        <div className="flex items-center gap-2">
                            {latestMoves.length > 0 ? (
                                latestMoves.map(({ id, move }) => (
                                    <img
                                        key={id}
                                        src={`/svg/${move}.svg`}
                                        alt={move}
                                        className="w-8 h-8"
                                    />
                                ))
                            ) : (
                                // Default placeholders when no moves are available
                                Array(5).fill(0).map((_, index) => (
                                    <img
                                        key={index}
                                        src="/svg/rock.svg"
                                        alt="rock"
                                        className="w-8 h-8 opacity-30"
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <p className="text-xs opacity-60">© 2025 Play RPS. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
