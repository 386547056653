/**
 * Service for handling file uploads and manipulation
 */

import { post, get } from './apiService';

/**
 * Get a signed URL for uploading a file to S3
 * @param fileName The name of the file to upload
 * @param contentType The MIME type of the file
 * @returns Promise resolving to an object containing the signedUrl for uploading and the readUrl for accessing the file
 */
export const getSignedUploadUrl = async (
  fileName: string, 
  contentType: string
): Promise<{ signedUrl: string, readUrl: string, fileName: string }> => {
  try {
    const response = await post('/api/files/get-signed-url', { 
      fileName, 
      contentType 
    });
    
    return response.data;
  } catch (error) {
    console.error('Error getting signed URL:', error);
    throw error;
  }
};

/**
 * Upload a file to the server/storage
 * @param file The file to upload
 * @param onProgress Optional callback for upload progress
 * @returns Promise resolving to the object path of the uploaded file (not the URL)
 */
export const uploadFile = async (
  file: File,
  onProgress?: (progress: number) => void
): Promise<string> => {
  try {
    // Get a signed URL for uploading
    const { signedUrl, readUrl, fileName } = await getSignedUploadUrl(file.name, file.type);
    
    console.log('Got signed URL and readUrl:', { signedUrl, readUrl, fileName });
    
    // Upload the file to the signed URL
    const xhr = new XMLHttpRequest();
    
    // Create a promise to handle the upload
    const uploadPromise = new Promise<string>((resolve, reject) => {
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', file.type);
      
      // Setup progress handler if provided
      if (onProgress) {
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            onProgress(progress);
          }
        };
      }
      
      xhr.onload = () => {
        if (xhr.status === 200) {
          // Return just the fileName (object path) to be stored in the database
          // This is the permanent identifier for the object, not the temporary signed URL
          resolve(fileName);
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };
      
      xhr.onerror = () => {
        reject(new Error('Upload failed due to network error'));
      };
      
      xhr.send(file);
    });
    
    return uploadPromise;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

/**
 * Get the full avatar URL for a given avatar path
 * @param avatarPath The relative path or full URL of the avatar
 * @returns Promise resolving to the full avatar URL
 */
export const getAvatarUrl = async (avatarPath: string | null | undefined): Promise<string | null> => {
  if (!avatarPath) return null;
  
  try {
    const response = await get('/api/files/get-avatar-url', { 
      params: { avatarPath } 
    });
    
    return response.data.url;
  } catch (error) {
    console.error('Error getting avatar URL:', error);
    return null;
  }
};

/**
 * Get avatar URL by wallet address
 * @param walletAddress The wallet address of the user
 * @returns Promise resolving to the full avatar URL or null if no avatar exists
 */
export const getAvatarByWallet = async (walletAddress: string | null | undefined): Promise<string | null> => {
  if (!walletAddress) return null;
  
  try {
    const response = await get('/api/files/get-avatar-by-wallet', { 
      params: { walletAddress } 
    });
    
    return response.data.url;
  } catch (error) {
    console.error('Error getting avatar by wallet:', error);
    return null;
  }
}; 