import * as React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { LegendItem } from "./LegendItem";
import { ChartSectionProps } from "./types";

const COLORS = ["#10B981", "#3B82F6", "#8B5CF6"]; // emerald-400, blue-500, violet-500 to match legendItems colors

export const ChartSection: React.FC<ChartSectionProps> = ({
  title,
  legendItems,
  values = [],
  className = '',
}) => {
  // Transform legendItems into data for the PieChart
  const data = legendItems.map((item, index) => ({
    name: item.label,
    value: values[index] || 0, // Use actual values if provided, otherwise 0
  }));

  // Calculate total for percentage
  const total = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <section className={`self-center px-20 py-9 mt-12 max-w-full rounded-2xl border border-solid bg-purple-dark border-purple w-[664px] max-md:px-5 max-md:mt-4 ${className}`}>
      <div className="flex gap-5 max-md:flex-col max-md:items-center">
        <div className="flex flex-col max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto w-full text-xl text-white whitespace-nowrap max-md:items-center">
            {legendItems.map((item, index) => (
              <LegendItem 
                key={index} 
                {...item} 
                value={values[index]}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[84%] max-md:ml-0 max-md:w-full max-md:items-center">
          <div className="flex flex-col grow text-2xl text-white max-md:items-center">
            <h3 className="self-start border-white max-md:self-center">{title}</h3>
            <div className="h-[400px] w-[400px] self-end mt-8 max-md:self-center max-md:h-[240px] max-md:w-[240px] max-md:mt-4">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={window.innerWidth < 768 ? 40 : 60}
                    outerRadius={window.innerWidth < 768 ? 90 : 130}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    contentStyle={{ backgroundColor: "#1E1E2F", border: "none" }}
                    itemStyle={{ color: "#fff" }}
                    formatter={(value: number, name: string) => {
                      const percentage = total > 0 ? ((value / total) * 100).toFixed(1) : "0";
                      return [`${value} (${percentage}%)`, name];
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
