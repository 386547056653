import { useState, useEffect } from 'react';
import { getAvatarByWallet } from '../services/fileService';

// Cache object to store avatar URLs by wallet address
interface CacheEntry {
  url: string;
  timestamp: number;
}

const avatarCache: Record<string, CacheEntry> = {};
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

/**
 * Hook to fetch an avatar URL by wallet address
 * @param walletAddress The wallet address to fetch the avatar for
 * @returns Object containing the avatarUrl, loading state, and any error
 */
export function useAvatarByWallet(walletAddress: string | undefined | null) {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!walletAddress) {
      setAvatarUrl(null);
      setIsLoading(false);
      return;
    }

    // Check if we have a valid cached entry
    const cachedEntry = avatarCache[walletAddress];
    const now = Date.now();
    
    if (cachedEntry && now - cachedEntry.timestamp < CACHE_DURATION) {
      // Use the cached avatar URL if it's still valid
      console.log('Using cached avatar URL for:', walletAddress);
      setAvatarUrl(cachedEntry.url);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    getAvatarByWallet(walletAddress)
      .then(url => {
        const finalUrl = url || '/img/user_placeholder.png'; // Default to placeholder
        console.log('Avatar URL:', walletAddress, finalUrl);
        
        // Update the cache
        avatarCache[walletAddress] = {
          url: finalUrl,
          timestamp: Date.now()
        };
        
        setAvatarUrl(finalUrl);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Error getting avatar by wallet:', err);
        const placeholderUrl = '/img/user_placeholder.png';
        
        // Cache the placeholder on error as well to avoid repeated failed requests
        avatarCache[walletAddress] = {
          url: placeholderUrl,
          timestamp: Date.now()
        };
        
        setAvatarUrl(placeholderUrl); // Use placeholder on error
        setError(err);
        setIsLoading(false);
      });
  }, [walletAddress]);

  return { avatarUrl, isLoading, error };
} 