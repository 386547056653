import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { formatNumber, truncateString } from "../helpers";
import { Game } from "../services/gameService";
import { useAvatarByWallet } from "../hooks/useAvatarByWallet";
import { getTotalUsersCount } from "../services/userService";
import { useGameContext } from "../context/GameContext";

interface BetItem {
    user: string;
    text: string;
    coin: number;
    time: string;
    originalDate: Date;
    walletAddress?: string;
}

// Component to display user avatar
const UserAvatar: React.FC<{ walletAddress?: string, altText: string }> = ({ walletAddress, altText }) => {
    const { avatarUrl, isLoading } = useAvatarByWallet(walletAddress);
    
    return (
        <img
            src={isLoading ? "/img/user_placeholder.png" : avatarUrl || "/img/user_placeholder.png"}
            alt={altText}
            className="w-8 h-8 rounded-full object-cover"
        />
    );
};

const Sidebar: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const [betItems, setBetItems] = useState<BetItem[]>([]);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    
    // Use the game context instead of fetching games directly
    const { games, isLoading } = useGameContext();

    // Helper to format a Date as relative time (e.g. "15s ago", "3m ago")
    const formatRelativeTime = (date: Date): string => {
        const now = new Date();
        const diffMs = now.getTime() - new Date(date).getTime();
        const diffSec = Math.floor(diffMs / 1000);
        if (diffSec < 60) return t('sidebar.time.seconds', { seconds: diffSec });
        const diffMin = Math.floor(diffSec / 60);
        if (diffMin < 60) return t('sidebar.time.minutes', { minutes: diffMin });
        const diffHr = Math.floor(diffMin / 60);
        if (diffHr < 24) return t('sidebar.time.hours', { hours: diffHr });
        const diffDay = Math.floor(diffHr / 24);
        return t('sidebar.time.days', { days: diffDay });
    };

    // Fetch total users count
    useEffect(() => {
        const fetchTotalUsers = async () => {
            try {
                const count = await getTotalUsersCount();
                setTotalUsers(count);
            } catch (error) {
                console.error('Error fetching total users:', error);
            }
        };

        fetchTotalUsers();
        // Refresh count every 5 minutes
        const interval = setInterval(fetchTotalUsers, 300000);
        return () => clearInterval(interval);
    }, []);

    // Update bet items when games change
    useEffect(() => {
        
        // Map each game into a bet item with the desired fields
        const items: BetItem[] = games
            .filter(game => {
                // Filter out any invalid games
                if (!game || typeof game !== 'object') {
                    console.warn('Invalid game object:', game);
                    return false;
                }
                return true;
            })
            .map((game: Game) => {
                try {
                    
                    // Get username or truncated userId with fallback
                    const username = game.username || 
                        (game.userId ? truncateString(game.userId, 6) : 'Unknown');
                    
                    // Get player choice with fallback
                    const playerChoice = game.playerChoice || 'rock';
                    const userChoiceText = t(`betting.moves.${playerChoice}`);
                    
                    // Get outcome with fallback
                    const outcome = game.outcome || 'draw';
                    const resultKey = `sidebar.result.${outcome.toLowerCase()}`;
                    
                    // Create text with proper fallbacks
                    const text = t('sidebar.betResult', { 
                        choice: userChoiceText, 
                        result: t(resultKey) 
                    });
                    
                    // Ensure timestamp is a valid date
                    let timestamp;
                    try {
                        timestamp = new Date(game.timestamp || Date.now());
                        // Verify it's a valid date
                        if (isNaN(timestamp.getTime())) {
                            console.warn('Invalid timestamp for game:', game._id);
                            timestamp = new Date();
                        }
                    } catch (e) {
                        console.warn('Error parsing timestamp:', e);
                        timestamp = new Date();
                    }
                    
                    return {
                        user: username,
                        text,
                        coin: typeof game.betAmount === 'number' ? game.betAmount : 0,
                        time: formatRelativeTime(timestamp),
                        originalDate: timestamp,
                        walletAddress: game.walletAddress
                    };
                } catch (error) {
                    // Log error and provide fallback
                    console.error('Error processing game:', error, game);
                    return {
                        user: 'Unknown',
                        text: t('sidebar.betResult', { 
                            choice: t('betting.moves.rock'), 
                            result: t('sidebar.result.draw') 
                        }),
                        coin: 0,
                        time: formatRelativeTime(new Date()),
                        originalDate: new Date(),
                        walletAddress: undefined
                    };
                }
            });
        
        setBetItems(items);
    }, [games, t]);

    // Update times every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            setBetItems(prevItems => 
                prevItems.map(item => ({
                    ...item,
                    time: formatRelativeTime(item.originalDate)
                }))
            );
        }, 1000);
        
        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    if (isLoading && betItems.length === 0) {
        return (
            <div className="w-[365px] flex-shrink-0 hidden lg:block">
                <aside className="hidden lg:flex m-4 sidebar-bg h-full transition-all duration-300 flex-col rounded-[10px] w-100 text-white">
                    <div className="flex items-center justify-center p-8">
                        <div className="w-8 h-8 border-t-2 border-pink border-opacity-50 rounded-full animate-spin"></div>
                    </div>
                </aside>
            </div>
        );
    }

    return (
        <div className={`max-w-[365px] flex-shrink-0 hidden lg:block`}>
            <aside
                className={`lg:flex m-4 sidebar-bg h-full transition-all duration-300 flex-col rounded-[10px] ${open ? "w-100" : "w-8"
                    } text-white`}
            >
                <button onClick={() => setOpen(!open)} className="self-end mt-3 mx-3">
                    <img
                        src="/img/hide.svg"
                        alt={t('sidebar.toggleAlt')}
                        className={`cursor-pointer ${open ? "" : "rotate-180"}`}
                    />
                </button>

                {open && (
                    <div className="flex flex-col flex-1 overflow-hidden">
                        <h3 className="text-md font-semibold mb-2 mx-2">
                            {t('sidebar.playersCount', { count: totalUsers })}
                        </h3>
                        <div className="overflow-y-auto flex-1 no-scrollbar scrollbar-none">
                            {betItems.map((bet: BetItem, i: number) => {
                                const rowBg = i % 2 === 1 ? "bg-[#D9D9D91F]" : "bg-transparent";
                                return (
                                    <div
                                        key={i}
                                        className={` ${rowBg} px-3 py-2 text-xs flex justify-between items-center`}
                                    >
                                        <div className="flex items-center gap-2">
                                            <UserAvatar 
                                                walletAddress={bet.walletAddress} 
                                                altText={t('sidebar.userAvatarAlt')} 
                                            />
                                            <span>
                                                {truncateString(bet.user, 10)} {bet.text}
                                            </span>
                                        </div>
                                        <div className="flex items-center gap-3 text-xs">
                                            <div className="flex items-center gap-1">
                                                <img
                                                    src="/img/sol_logo.png"
                                                    alt={t('sidebar.solLogoAlt')}
                                                    className="w-4 h-4"
                                                />
                                                <span>{formatNumber(bet.coin)}</span>
                                            </div>
                                            <span className="text-center w-[50px]">{bet.time}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </aside>
        </div>
    );
};

export default Sidebar;
