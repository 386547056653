import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/UserContext';

interface ReferralLinkProps {
  solanaAddress: string;
}

const ReferralLink: React.FC<ReferralLinkProps> = ({ solanaAddress }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [copied, setCopied] = useState(false);

  // Base URL for referral link
  const baseUrl = window.location.origin;
  
  // Use only referralCode from database
  const referralCode = user?.referralCode || '';
  
  // Full referral link
  const referralLink = `${baseUrl}?code=${referralCode}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="bg-purple p-4 rounded-lg mb-4">
      <h2 className="text-xl font-bold mb-4">{t('referral.yourLink')}</h2>
      <div className="flex flex-col md:flex-row gap-4 items-center">
        <div className="bg-black/30 p-3 rounded-lg flex-1 overflow-hidden">
          <p className="text-sm md:text-base truncate font-mono">{referralLink}</p>
        </div>
        <button 
          onClick={copyToClipboard} 
          className="bg-purple-light hover:bg-pink text-white px-6 py-2 rounded-lg transition-colors"
        >
          {copied ? t('referral.copied') : t('referral.copy')}
        </button>
      </div>
    </div>
  );
};

export default ReferralLink;
