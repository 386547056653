import { post, get, put } from './apiService';

/**
 * User object interface
 */
export interface User {
  _id: string;
  username: string;
  walletAddress: string;
  email?: string;
  solanaWallet?: string;
  referralCode?: string;
  referredBy?: string;
  points?: number;
  totalPlays?: number;
  totalWins?: number;
  totalVolume?: number;
  enabled?: boolean;
  avatarUrl?: string;
  createdAt: Date;
  playerChoices?: {
    rock: number;
    paper: number;
    scissors: number;
  };
}

/**
 * Default user state
 */
export const defaultUser: User = {
  _id: '',
  username: '',
  walletAddress: '',
  createdAt: new Date()
};

/**
 * Interface for user settings
 */
export interface UserSettings {
  soundEnabled: boolean;
  notificationsEnabled: boolean;
  theme: 'light' | 'dark' | 'system';
  language: string;
}

/**
 * Default user settings
 */
export const defaultUserSettings: UserSettings = {
  soundEnabled: true,
  notificationsEnabled: true,
  theme: 'system',
  language: 'en'
};

/**
 * Initialize a user or get an existing user by wallet address
 */
export const initializeUser = async (walletAddress: string, storedReferral?: string): Promise<User> => {
  try {
    const response = await post('/api/users/initialize', { 
      walletAddress, 
      storedReferral 
    });
    
    return response.data.user;
  } catch (error) {
    console.error('Error initializing user:', error);
    throw error;
  }
};

/**
 * Get user by wallet address
 */
export const getUserByWalletAddress = async (walletAddress: string): Promise<User | null> => {
  try {
    const response = await get('/api/users', { 
      params: {
        search: walletAddress,
        limit: 1
      }
    });
    
    return response.data.users.length > 0 ? response.data.users[0] : null;
  } catch (error) {
    console.error('Error getting user by wallet address:', error);
    return null;
  }
};

/**
 * Get public user data by wallet address (no authentication required)
 * Used for fetching usernames and avatars that are public information
 */
export const getPublicUserData = async (walletAddress: string): Promise<Pick<User, 'username' | 'avatarUrl'> | null> => {
  try {
    const response = await get('/api/users/public', { 
      params: {
        walletAddress
      }
    });
    
    return response.data.user || null;
  } catch (error) {
    console.error('Error getting public user data:', error);
    return null;
  }
};

/**
 * Update user profile
 */
export const updateUserProfile = async (walletAddress: string, updates: Partial<User>): Promise<User> => {
  try {
    console.log('Updating user profile:', updates);
    const response = await put(`/api/users/${walletAddress}`, updates);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

/**
 * Get all users (for admin purposes)
 */
export const getAllUsers = async (): Promise<User[]> => {
  try {
    const response = await get('/api/users');
    return response.data.users;
  } catch (error) {
    console.error('Error getting all users:', error);
    return [];
  }
};

/**
 * Toggle a user's enabled status
 * @param userId The user ID
 * @param enabled The new enabled status
 * @returns Promise resolving to success status
 */
export const toggleUserStatus = async (userId: string, enabled: boolean): Promise<boolean> => {
  try {
    const response = await put(`/api/users/toggle-status/${userId}`, { enabled });
    return response.data.success;
  } catch (error) {
    console.error('Error toggling user status:', error);
    return false;
  }
};

/**
 * Get the current user from API or local storage
 * @returns Promise resolving to the current user
 */
export const getCurrentUser = async (): Promise<User | null> => {
  // Check if we have a token
  const token = localStorage.getItem('authToken');
  if (!token) return null;
  
  try {
    const response = await get('/api/user/me');
    return response.data;
  } catch (error) {
    console.error('Error getting current user:', error);
    // Clear token on auth error
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    return null;
  }
};

/**
 * Get user settings
 * @returns Promise resolving to the user settings
 */
export const getUserSettings = async (): Promise<UserSettings> => {
  try {
    const response = await get('/api/user/settings');
    return response.data;
  } catch (error) {
    console.error('Error getting user settings:', error);
    return { ...defaultUserSettings };
  }
};

/**
 * Update user settings
 * @param settings Partial user settings to update
 * @returns Promise resolving to the updated settings
 */
export const updateUserSettings = async (settings: Partial<UserSettings>): Promise<UserSettings> => {
  try {
    const response = await post('/api/user/settings', settings);
    return response.data;
  } catch (error) {
    console.error('Error updating user settings:', error);
    return { ...defaultUserSettings, ...settings };
  }
};

/**
 * Connect a Solana wallet to the user account
 * @param walletAddress The Solana wallet address to connect
 * @returns Promise resolving to the updated user
 */
export const connectSolanaWallet = async (walletAddress: string): Promise<User> => {
  try {
    const response = await post('/api/user/connect-wallet', { walletAddress });
    return response.data;
  } catch (error) {
    console.error('Error connecting Solana wallet:', error);
    throw error;
  }
};

/**
 * Sign out the current user
 */
export const signOut = async (): Promise<void> => {
  try {
    await post('/api/auth/logout');
  } catch (error) {
    console.error('Error during sign out:', error);
  } finally {
    // Clear local storage regardless of API response
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
  }
};

/**
 * Get total count of enabled users
 * @returns Promise resolving to the total count of enabled users
 */
export const getTotalUsersCount = async (): Promise<number> => {
  try {
    const response = await get('/api/users/count');
    return response.data.count;
  } catch (error) {
    console.error('Error getting total users count:', error);
    return 0;
  }
}; 