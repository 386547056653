import axios from 'axios';
import { User } from "./userService";
import { post, put, get } from "./apiService";
import { API_BASE_URL } from "../../imports/utils";

const API_URL = API_BASE_URL + '/api';

/**
 * Default game settings
 */
export const DEFAULT_SETTINGS = {
  min_bet: 0.01,
  max_bet: 5,
  max_volume_hourly: 100,
  max_volume_daily: 1000,
  house_commission: 0.01 // 1%
};

/**
 * Interface for site settings
 */
export interface SiteSettings {
  min_bet: number;
  max_bet: number;
  max_volume_hourly: number;
  max_volume_daily: number;
}

/**
 * Interface for extended user document with admin-specific fields
 */
export interface AdminUserDocument extends User {
  totalPlays?: number;
  totalVolume?: number;
  enabled?: boolean;
  isAdmin?: boolean;
  createdAt: Date;
}

/**
 * Interface for paginated user response
 */
export interface PaginatedUsers {
  users: AdminUserDocument[];
  totalUsers: number;
}

/**
 * Helper function to get the stored token
 */
const getToken = (): string | null => {
  return localStorage.getItem('rps_auth_token');
};

/**
 * Helper to create authenticated headers
 */
const getAuthHeaders = () => {
  const token = getToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
};

/**
 * Auth functions
 */
export const requestNonce = async (walletAddress: string): Promise<string> => {
  try {
    const response = await axios.get(`${API_URL}/auth/nonce?walletAddress=${walletAddress}`);
    return response.data.nonce;
  } catch (error: any) {
    console.error('Error requesting nonce:', error);
    throw new Error(error.response?.data?.error || 'Failed to request nonce');
  }
};

export const verifySignature = async (walletAddress: string, signature: string) => {
  try {
    const response = await axios.post(`${API_URL}/auth/verify`, { walletAddress, signature });
    
    // Store the token and user info
    localStorage.setItem('rps_auth_token', response.data.token);
    localStorage.setItem('rps_user', JSON.stringify(response.data.user));
    
    return response.data;
  } catch (error: any) {
    console.error('Error verifying signature:', error);
    throw new Error(error.response?.data?.error || 'Failed to verify signature');
  }
};

export const isAuthenticated = (): boolean => {
  return !!getToken();
};

export const isAdmin = (): boolean => {
  const userStr = localStorage.getItem('rps_user');
  if (!userStr) return false;
  
  try {
    const user = JSON.parse(userStr);
    return !!user.isAdmin;
  } catch {
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('rps_auth_token');
  localStorage.removeItem('rps_user');
  // Redirect to home or login page if needed
  window.location.href = '/';
};

/**
 * Get users with pagination and search functionality
 */
export const getUsers = async (
  page: number = 1,
  limit: number = 25,
  search: string = ""
): Promise<PaginatedUsers> => {
  try {
    const response = await axios.get(
      `${API_URL}/users?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`, 
      getAuthHeaders()
    );
    
    return {
      users: response.data.users,
      totalUsers: response.data.total
    };
  } catch (error: any) {
    console.error('Error fetching users:', error);
    throw new Error(error.response?.data?.error || 'Failed to fetch users');
  }
};

/**
 * Toggle user enabled status
 */
export const toggleUserStatus = async (userId: string, enabled: boolean): Promise<boolean> => {
  try {
    const response = await axios.put(
      `${API_URL}/users/toggle-status/${userId}`, 
      { enabled }, 
      getAuthHeaders()
    );
    
    return response.data.success;
  } catch (error: any) {
    console.error('Error toggling user status:', error);
    throw new Error(error.response?.data?.error || 'Failed to toggle user status');
  }
};

/**
 * Get current commission rate from blockchain
 */
export const getCommissionRate = async (): Promise<number> => {
  try {
    const response = await axios.get(`${API_URL}/site/commission-rate`);
    return response.data.rate;
  } catch (error: any) {
    console.error('Error fetching commission rate:', error);
    throw new Error(error.response?.data?.error || 'Failed to fetch commission rate');
  }
};

/**
 * Update commission rate on blockchain
 */
export const updateCommissionRate = async (rate: number): Promise<boolean> => {
  try {
    const response = await axios.put(
      `${API_URL}/site/commission-rate`, 
      { rate }, 
      getAuthHeaders()
    );
    
    return !!response.data.success;
  } catch (error: any) {
    console.error('Error updating commission rate:', error);
    throw new Error(error.response?.data?.error || 'Failed to update commission rate');
  }
};

/**
 * Get a specific site setting value
 * @param settingName The name of the setting to retrieve
 * @returns Promise resolving to the setting value
 */
export const getSiteSetting = async (settingName: keyof SiteSettings): Promise<number> => {
  try {
    const response = await axios.get(`${API_URL}/site/settings/${settingName}`);
    
    // Return default if no value is found
    return response.data.value !== undefined ? response.data.value : DEFAULT_SETTINGS[settingName];
  } catch (error: any) {
    console.error(`Error fetching setting ${settingName}:`, error);
    return DEFAULT_SETTINGS[settingName]; // Fallback to default on error
  }
};

/**
 * Update a site setting
 * @param settingName The name of the setting to update
 * @param value The new value for the setting
 * @returns Promise resolving to a success indicator
 */
export const updateSiteSetting = async (settingName: string, value: any): Promise<boolean> => {
  try {
    const response = await axios.put(
      `${API_URL}/site/settings/${settingName}`, 
      { value }, 
      getAuthHeaders()
    );
    
    return response.data.success;
  } catch (error: any) {
    console.error(`Error updating setting ${settingName}:`, error);
    throw new Error(error.response?.data?.error || `Failed to update setting ${settingName}`);
  }
};

/**
 * Get all site settings
 */
export const getAllSettings = async (): Promise<SiteSettings> => {
  try {
    const response = await axios.get(`${API_URL}/site/settings`, getAuthHeaders());
    
    const settings = response.data.settings || {};
    
    // Merge with defaults for any missing settings
    return {
      min_bet: settings.min_bet !== undefined ? settings.min_bet : DEFAULT_SETTINGS.min_bet,
      max_bet: settings.max_bet !== undefined ? settings.max_bet : DEFAULT_SETTINGS.max_bet,
      max_volume_hourly: settings.max_volume_hourly !== undefined ? settings.max_volume_hourly : DEFAULT_SETTINGS.max_volume_hourly,
      max_volume_daily: settings.max_volume_daily !== undefined ? settings.max_volume_daily : DEFAULT_SETTINGS.max_volume_daily
    };
  } catch (error: any) {
    console.error('Error fetching all settings:', error);
    return DEFAULT_SETTINGS; // Fallback to defaults on error
  }
}; 