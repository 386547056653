/**
 * API Service - centralizes API configuration and requests
 * 
 * This service provides:
 * 1. Base URL configuration
 * 2. Standard request methods (get, post, put, delete)
 * 3. Error handling
 * 4. Authentication header management
 */

import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { API_BASE_URL } from '../../imports/utils';

// Base API URL - use the one from utils.ts which handles environment correctly
// const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || `${window.location.origin}/api`;

/**
 * Create axios instance with default configuration
 */
const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000, // 30 seconds
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Request interceptor for API calls
 */
api.interceptors.request.use(
  (config) => {
    // Skip auth headers for public endpoints
    if (config.url?.includes('/public')) {
      return config;
    }
    
    // TEMPORARY: Authentication is disabled for development
    // Get auth token from localStorage
    // const token = localStorage.getItem('authToken');
    
    // If token exists, add to headers
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Response interceptor for API calls
 */
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // TEMPORARY: Bypass authentication error handling for development
    return Promise.reject(error);
    
    /*
    const originalRequest = error.config;
    
    // If error is 401 Unauthorized and not already retrying
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        // Attempt to refresh token
        const refreshToken = localStorage.getItem('refreshToken');
        
        if (refreshToken) {
          const response = await axios.post(`${API_BASE_URL}/auth/refresh`, {
            refreshToken,
          });
          
          const { token } = response.data;
          
          // Store new token
          localStorage.setItem('authToken', token);
          
          // Update Authorization header
          originalRequest.headers.Authorization = `Bearer ${token}`;
          
          // Retry original request
          return api(originalRequest);
        }
      } catch (refreshError) {
        // If refresh fails, clear tokens and redirect to login
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        
        // Redirect to login page
        window.location.href = '/login';
      }
    }
    
    return Promise.reject(error);
    */
  }
);

/**
 * GET request helper
 * @param url API endpoint
 * @param config Optional axios config
 * @returns Promise with response
 */
export const get = <T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return api.get<T>(url, config);
};

/**
 * POST request helper
 * @param url API endpoint
 * @param data Request body
 * @param config Optional axios config
 * @returns Promise with response
 */
export const post = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return api.post<T>(url, data, config);
};

/**
 * PUT request helper
 * @param url API endpoint
 * @param data Request body
 * @param config Optional axios config
 * @returns Promise with response
 */
export const put = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return api.put<T>(url, data, config);
};

/**
 * DELETE request helper
 * @param url API endpoint
 * @param config Optional axios config
 * @returns Promise with response
 */
export const del = <T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return api.delete<T>(url, config);
};

/**
 * Handle API errors consistently
 * @param error Error object from catch block
 * @param fallbackMessage Default message if error doesn't have response data
 * @returns Formatted error message
 */
export const handleApiError = (error: any, fallbackMessage = 'An error occurred'): string => {
  if (error.response?.data?.message) {
    return error.response.data.message;
  }
  
  if (error.message) {
    return error.message;
  }
  
  return fallbackMessage;
};

// Export a default object for easier imports
export default {
  get,
  post,
  put,
  delete: del,
}; 